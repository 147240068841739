import { SET_MESSAGE, CLEAR_MESSAGE, SET_FMESSAGE } from "../actions/types";

const initialState = {
  message:'',
  msg_type:''
};

const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { 
        message: payload,
        msg_type:'alert-success'
      };
    case SET_FMESSAGE:
      return { 
        message: payload,
        msg_type:'alert-danger'
      };
    case CLEAR_MESSAGE:
      return { message: "", msg_type: "" };
    default:
      return state;
  }
};
export default messageReducer;