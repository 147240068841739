import { combineReducers } from "redux";
import homeReducer from "./homeReducer";
import galleryReducer from "./galleryReducer";
import mediaReducer from "./mediaReducer";
import authReducer from "./auth";
import messageReducer from "./message";
import wisdomReducer from "./wisdomReducer";

export default combineReducers({
  homeReducer, galleryReducer, mediaReducer, authReducer, messageReducer, wisdomReducer
});
