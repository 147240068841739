export const SLIDER_LIST = "SLIDER_LIST";
export const PROGRAM_LIST = "PROGRAM_LIST";
export const PROJECT_LIST = "PROJECT_LIST";
export const EVENT_LIST = "EVENT_LIST";
export const CATEGORY_EVENT = "CATEGORY_EVENT";
export const EVENTALL_LIST = "EVENTALL_LIST";
export const TRANS_LIST = "TRANS_LIST";
export const VIDEO_LIST = "VIDEO_LIST";
export const AUDIO_LIST = "AUDIO_LIST";
export const WISDOM_DETAIL = "WISDOM_DETAIL"; 

export const RECENT_LIST = "RECENT_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_DETAIL = "CATEGORY_DETAIL";
export const TAG_DETAIL = "TAG_DETAIL";
export const FILTER_GALLERY = "FILTER_GALLERY";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_FMESSAGE = "SET_FMESSAGE";

export const COUNTRY_LIST = "COUNTRY_LIST";
export const STATE_LIST = "STATE_LIST";
export const CITY_LIST = "CITY_LIST";

export const PAYMENT_DATA = "PAYMENT_DATA";
export const RESULT_DATA = "RESULT_DATA";

export const PROFILE_DATA = "PROFILE_DATA";
export const CURRENT_LIST = "CURRENT_LIST";
export const HISTORY_LIST = "HISTORY_LIST";
export const REGISTER_DETAIL = "REGISTER_DETAIL";
export const PAYMENT_LIST = "PAYMENT_LIST";
export const PAYMENTFAILED_LIST = "PAYMENTFAILED_LIST";
export const GLIMPSE_LIST = 'GLIMPSE_LIST';
export const FAVOURITE_LIST ='FAVOURITE_LIST';

export const ALLMEDIA_LIST = "ALLMEDIA_LIST";
export const FILTER_MEDIA = "FILTER_MEDIA";
export const MEDIACATEGORY_DETAIL = "MEDIACATEGORY_DETAIL";
export const MEDIATAG_DETAIL = "MEDIATAG_DETAIL";
export const MANDIR_PAYLINK = "MANDIR_PAYLINK";
export const DATA_FETCH ="DATA_FETCH";
export const FEEDBACK_LIST ="FEEDBACK_LIST";