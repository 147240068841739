import {
    RECENT_LIST,
    CATEGORY_LIST,
    CATEGORY_DETAIL,
    TAG_DETAIL,
    FILTER_GALLERY
  } from "../actions/types";
  
  const initialState = {
    recent_lists: [],
    category_lists: [],
    category_detail:[],
    filter_gallery:[],
    tag_detail:[],
    datafetched: false,
    loading: false,
    message: '',
    isDeleted: false
  };
  
  
  const galleryReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case RECENT_LIST:
        return {
          ...state,
          recent_lists: action.payload,
          datafetched: true
        };
      case CATEGORY_LIST:
        return {
          ...state,
          category_lists: action.payload,
          datafetched: true
        };
      case CATEGORY_DETAIL:
        return {
          ...state,
          category_detail: action.payload,
          datafetched: true
        };
      case TAG_DETAIL:
        return {
          ...state,
          tag_detail: action.payload,
          datafetched: true
        };
      case FILTER_GALLERY:
        return {
          ...state,
          filter_gallery: action.payload,
          datafetched: true
        };
      default:
        return state;
    }
  };
  
  export default galleryReducer;
  