import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { feedback } from "../../actions/api-wisdom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Divijafeed = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const reg_id = localStorage.getItem("reg_id");

    const { message } = useSelector(state => state.messageReducer);
    const initialState = {
        reg_id: reg_id,
        experience: "",
        goal_before: "",
        goal_after: "",
        things_notforget: "",
        recommend_program: "",
        wish_kaivalya: "",
        about_program: "",
    };
    const [datas, setDatas] = useState(initialState);

    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "FeedBack | Matangi";
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
  
    const handleContact = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(feedback(datas))
            .then(() => {
              localStorage.setItem('reg_id', 0);
              localStorage.setItem('regprogram_id', 0);
              setSuccessful(true);
            })
            .catch(() => {
              setSuccessful(false);
            });
        }
    };

  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
                    <div className="col-md-12">
					<div className="col-md-10">
                    {!successful && (
						<div className="login-right">
							<h3>Divija - Feedback</h3>
							<p></p>
							<Form onSubmit={handleContact} ref={form}>
								<div className="form-group">
                    <label> What Have you understand about life(Everything) after the Divija experience?</label>
                    <Input type="text" className="form-control" name="experience" value={ datas.experience } onChange={handleInputChange} validations={[required]} placeholder="What Have you understand about life(Everything) after the Divija experience?..."/>
								</div>
								<div className="form-group">
                    <label> What was your goal in life before the start of the program?</label>
                    <Input type="text" className="form-control" name="goal_before" value={ datas.goal_before } onChange={handleInputChange} validations={[required]} placeholder="What was your goal in life before the start of the program?..." />
								</div>
                <div className="form-group">
                    <label> What is your goal in life now after undergoing this program?</label>
                    <Input type="text" className="form-control" name="goal_after" value={ datas.goal_after } onChange={handleInputChange} validations={[required]} placeholder="What is your goal in life now after undergoing this program?..." />
								</div>
                <div className="form-group">
                    <label> Mention 3 important things, which you cannot forget from the program?</label>
                    <Input type="text" className="form-control" name="things_notforget" value={ datas.things_notforget } onChange={handleInputChange} validations={[required]} placeholder="Mention 3 important things, which you cannot forget from the program?..." />
								</div>
                <div className="form-group">
                    <label> Will you recommend this program for your friends and or relatives?</label>
                    <Input type="text" className="form-control" name="recommend_program" value={ datas.recommend_program } onChange={handleInputChange} validations={[required]} placeholder="Will you recommend this program for your friends and or relatives?..." />
								</div>
                <div className="form-group">
                    <label> Do you wish to come for kaivalya 8 day program?</label>
                    <Input type="text" className="form-control" name="wish_kaivalya" value={ datas.wish_kaivalya } onChange={handleInputChange} validations={[required]} placeholder="Do you wish to come for kaivalya 8 day program?..." />
								</div>
                <div className="form-group">
                    <label> Do you Have anything else to say about this program?</label>
                    <Input type="text" className="form-control" name="about_program" value={ datas.about_program } onChange={handleInputChange} validations={[required]} placeholder="Do you Have anything else to say about this program?..." />
								</div>
								<button className="btn btn-primary loginbtn">Submit</button>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                            
                {message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                )}

						</div>
              )}

              {successful &&(
                <div className="login-right">
                <h4>Feedback Updated Successfully</h4>
                <p><Link to={"/profile"}>Back to Home</Link></p>
                </div>
              )}
					</div>
        </div>
                    
				</div>
			</div>
		</section>
  );
};

export default Divijafeed;
