import {
    EVENT_LIST,
    PAYMENT_DATA,
    RESULT_DATA,
    TRANS_LIST,
    FEEDBACK_LIST,
    CATEGORY_EVENT,
  } from "../actions/types";
  
  const initialState = {
    event_lists:[],
    category_event:[],
    trans_lists:[],
    payment_data:'',
    result_data:'',
    feedback_list:'',
    datafetched: false,
    loading: false,
    message: '',
    isDeleted: false
  };
  
  const wisdomReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case EVENT_LIST:
        return {
          ...state,
          event_lists: action.payload,
          datafetched: true
        };
      case CATEGORY_EVENT:
        return {
          ...state,
          category_event: action.payload,
          datafetched: true
        };
      case TRANS_LIST:
        return {
          ...state,
          trans_lists: action.payload,
          datafetched: true
        };
      case FEEDBACK_LIST:
        return {
          ...state,
          feedback_list: action.payload,
          datafetched: true
        };
      case PAYMENT_DATA:
        return {
          ...state,
          payment_data: action.payload,
          datafetched: true
        };
      case RESULT_DATA:
        return {
          ...state,
          result_data: action.payload,
          datafetched: true
        };
      default:
        return state;
    }
  };
  
  export default wisdomReducer;
  