import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import { isEmpty } from 'lodash';
import { allList, filterList } from "../actions/api-media";
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'rc-datepicker/lib/style.css';
import { useWish } from '../hooks';

const Media = (props) => {
	const dispatch = useDispatch();
  	const media = useSelector(state => state),
    { mediaReducer } = media,
    { all_lists, filter_media } = mediaReducer;
	
	const [open, setOpen] = useState(false);
    const [videourl, setVideourl] = useState('');
	const [filtertitle, setFiltertitle] = useState('all');  
	const [allmedia,setAllmedia]=useState([]);
	const [filtermedia, setFilterMedia] = useState([]);
	const [filtermsg, setFilterMsg] = useState('Search result...');
	const [tags,setTag]=useState([]);
    const [category,setCategory]=useState([]);
	const [submitted, setSubmitted] = useState(false);
	const initialState = {
		startDate: "",
		endDate: "",
		searchtxt: ""
	  };
	const [filters, setFilters] = useState(initialState);
	useEffect(() => {
		document.title = "Media | Matangi";
		localStorage.removeItem("navtype");
  		localStorage.removeItem("event_id");
		dispatch(allList(filtertitle));
	}, [dispatch]);

	useEffect(() => {
		if(!isEmpty(all_lists.data)) {
			setAllmedia(all_lists.data.slice(0, 8));
		}
		if(!isEmpty(all_lists.tags)) {
			setTag(all_lists.tags);
		}
        if(!isEmpty(all_lists.categories)) {
			setCategory(all_lists.categories);
		}
		if(!isEmpty(filter_media)) {
			if(filter_media.success ===0){
				setFilterMsg(filter_media.message);
				setFilterMedia([]);
			}else{
				setFilterMsg('Search result...');
				if (!isEmpty(filter_media.data)) {
					setFilterMedia(filter_media.data);
				}
			}
		}
	}, [all_lists, filter_media]);

	function validate(startDate, endDate, searchtxt) {
		if((startDate ==='' || startDate ===null) && (endDate ==='' || endDate ===null) && searchtxt ==='') {
			return false
		}
		else {
			return true;
		}
	}

	const handleInputChange = event => {
		setFilters({ ...filters, ['searchtxt']: event.target.value });
	};

	const setStart = (date) => {
    	setFilters({ ...filters, ['startDate']: date });
	};

	const setEnd = (date) => {
		setFilters({ ...filters, ['endDate']: date });
	};

	const filterMedia = () => {
		const { startDate, endDate, searchtxt } = filters;
		const errors = validate(startDate, endDate, searchtxt);
		if(errors) {
			dispatch(filterList(filtertitle, startDate, endDate, searchtxt));
			setSubmitted(true);
		}
		else {
			setFilterMedia([]);
			setFilters(initialState);
			setSubmitted(false);
			dispatch(allList(filtertitle));
		}
	};

	const filterReset = () => {
		setFilterMedia([]);
		setFilters(initialState);
		setSubmitted(false);
		dispatch(allList());
	};

	const filtertype = (e) => {
		const filter_type = e.target.dataset.type;
		setFiltertitle(filter_type);
		dispatch(allList(filter_type));
	};

	const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
    }

    const onOpenModal = (e) => {
        var videlink = e.currentTarget.dataset.video;
        setVideourl(videlink);
        setOpen(true);
    };
    const onCloseModal = () => setOpen(false);
    const copyToClipBoard = async copyMe => {
        try {
			var link = copyMe.match(/\bhttps?:\/\/\S+/gi);
			var final_link = link[0].replace(/^"|"$/g, '');
			var source = "https://api.whatsapp.com/send?text="+final_link;
      		window.open(source,'_blank','width=626,height=436');
			// await navigator.clipboard.writeText(final_link);
			// toast('Link Copied Successfully', {
			// 	theme: "dark"
			// });
        } catch (err) {
    
        }
    };

    const { isMediaOnWish, addToMediaWish } = useWish();
    const addMedia = (e) => {
        var source = e.currentTarget.getAttribute("data-id");
        addToMediaWish(source);
    };

	const [shareopen, setShareOpen] = useState(false);
	const [sharelink, setShareLink] = useState('');
	const onShareOpenModal = async copyMe => {
        try {
			var link = copyMe.match(/\bhttps?:\/\/\S+/gi);
			var final_link = link[0].replace(/^"|"$/g, '');
			setShareLink(final_link)
			setShareOpen(true);
			await navigator.clipboard.writeText(final_link);
			toast('Link Copied Successfully', {
				theme: "dark"
			});
			
        } catch (err) {
    
        }
    };
    const onShareCloseModal = () => setShareOpen(false);
  return (
    <div>
        <section className="section gallerytop">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="mainnavigation text-center">
							<Link to="/gallery" className="buttonclass">Image Callery</Link>
							<Link to="/media" className="buttondangerclass">AV Gallery</Link>
						</div>
					</div>
					<div className="col-md-12">
						<button className={filtertitle =='all' ? 'buttondangerclass' : 'buttonclass'} onClick={filtertype} data-type="all">Video & Audio</button>
						<button className={filtertitle =='video' ? 'buttondangerclass' : 'buttonclass'} onClick={filtertype} data-type="video">Videos</button>
						<button className={filtertitle =='audio' ? 'buttondangerclass' : 'buttonclass'} onClick={filtertype} data-type="audio">Audios</button>
					</div>
					
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-2">
								<h4>Date</h4>
							</div>
							<div className="col-md-4">
							<DatePickerInput
								maxDate={new Date()}
								className='my-react-datepicker'
								value={filters.startDate}
								showOnInputClick
								displayFormat='DD/MM/YYYY'
								returnFormat='YYYY-MM-DD'
								placeholder='Start Date'
								onChange={(jsDate) => setStart(jsDate)}
							/>
							</div>
							<div className="col-md-4">
							<DatePickerInput
								maxDate={new Date()}
								className='my-react-datepicker'
								value={filters.endDate}
								showOnInputClick
								displayFormat='DD/MM/YYYY'
								returnFormat='YYYY-MM-DD'
								placeholder='End Date'
								onChange={(jsDate) => setEnd(jsDate)}
							/>
							</div>
							<div className="col-md-2">
								<button className="buttonclass" onClick={filterMedia}>Go</button>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-6">
								<input type="text" className="form-control" value={ filters.searchtxt } onChange={handleInputChange} placeholder="Search..." />
							</div>
							<div className="col-md-5">
								<button className="buttonclass" onClick={filterMedia}>Go</button>
								<button className="buttondangerclass" onClick={filterReset}>Reset</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
        <section className="section-recent">
			<div className="container">
				<div className="row">
				{submitted ? (
					<div className="col-md-12">
						<p>{filtermsg}</p>
					</div>
				) : (
					<div className="col-md-12">
						<div className="title-sec">
						{filtertitle =='all' && (
						<h1 className="text-center">Recent Videos and Audios</h1>
						)}
						{filtertitle =='video' && (
						<h1 className="text-center">Recent Videos</h1>
						)}
						{filtertitle =='audio' && (
						<h1 className="text-center">Recent Audios</h1>
						)}
						</div>
					</div>
				)}
					<div className="col-md-12">
						<div id="content-page-one">
							<div className="row">
						{submitted ? (
							filtermedia && filtermedia.map((fmedia, findex) => (
								<div className="col-md-3" key={findex}>
									<div className="thumb-box">
									<div className="thumb-img">
										<div className="media-img mediavideo-img">
										<img src={ fmedia.image } alt="" onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
										</div>
										<div className="video-info">
										{fmedia.media_time !=='' && (
										<div className="info-time">
											<p>{ fmedia.media_time }</p>
										</div>
										)}
										<div className="info-share">
											<p className="first" data-for="share" data-tip="Click to Copy the Link" data-iscapture="true" onClick={ () => onShareOpenModal(fmedia.media) }><span className="fa fa-share-alt"></span></p>
			
											<p className={ isMediaOnWish(fmedia.id) ? 'second wished' : 'second'} onClick={addMedia} data-id={fmedia.id}>
											<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" className="svg-inline--fa fa-heart fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
											</p>
										</div>
										<div className="playbtn">
											<div className="sonar-emitter" data-video={ fmedia.media } onClick={onOpenModal}>
												<div className="sonar-wave sonar-wave1"></div>
												<div className="sonar-wave sonar-wave2"></div><span className="fa fa-play"></span>
											</div>
										</div>
										</div>
									</div>
									<div className="thumb-content">
										<h5>{ fmedia.title.length > 55 ? fmedia.title.substring(0, 40)+ "..."  : fmedia.title }</h5>
									</div>
									</div>
								</div>
							))
							) :
							<>
								<div className="col-lg-12 col-md-12">
								{ allmedia && allmedia.map((video, sindex) => (
									<div className="col-md-3" key={sindex}>
                                        <div className="thumb-box">
                                        <div className="thumb-img">
                                            <div className="media-img mediavideo-img">
                                            <img src={ video.image } alt="" onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
                                            </div>
                                            <div className="video-info">
                                            {video.media_time !=='' && (
                                            <div className="info-time">
                                                <p>{ video.media_time }</p>
                                            </div>
                                            )}
                                            <div className="info-share">
                                                <p className="first" data-for="share" data-tip="Click to Copy the Link" data-iscapture="true" onClick={ () => onShareOpenModal(video.media) }><span className="fa fa-share-alt"></span></p>
                
                                                <p className={ isMediaOnWish(video.id) ? 'second wished' : 'second'} onClick={addMedia} data-id={video.id}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" className="svg-inline--fa fa-heart fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                                </p>
                                            </div>
                                            <div className="playbtn">
                                                <div className="sonar-emitter" data-video={ video.media } onClick={onOpenModal}>
                                                    <div className="sonar-wave sonar-wave1"></div>
                                                    <div className="sonar-wave sonar-wave2"></div><span className="fa fa-play"></span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="thumb-content">
                                            <h5>{ video.title.length > 55 ? video.title.substring(0, 40)+ "..."  : video.title }</h5>
                                        </div>
                                        </div>
                                    </div>
  ))}
								</div>
								</>
							}

							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="taglist">
						<ul>
							{ tags && tags.map((tag, tagindex) => (
							<li key={tagindex}><span className="green-sm-dot"></span> <Link to={"/mediatag/"+tag.id}>{tag.album_tag}</Link></li>
							))}
						</ul>	
						</div>	
					</div>
				</div>
			</div>
		</section>

        <section className="section-album">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className="title-sec">
							{filtertitle =='all' && (
							<h1 className="text-center">Videos and Audios</h1>
							)}
							{filtertitle =='video' && (
							<h1 className="text-center">Videos</h1>
							)}
							{filtertitle =='audio' && (
							<h1 className="text-center">Audios</h1>
							)}
						</div>
					</div>
					
					<div className="col-sm-12">
                        { category && category.map((category, catindex) => (        
						<div className="row" key={catindex}>
                            <div className="col-md-12">
								<div className="title-sec"><h3>{ category.media_category} <Link to={"/mediacategory/" + category.id} className="viewclass">VIEW ALL</Link></h3>
                                
                                </div>
							</div>
                            <div className="col-lg-12 col-md-12">
                                { category.medias && category.medias.map((media, mediaindex) => (
                                    <div className="col-md-3" key={mediaindex}>
                                        <div className="thumb-box">
                                        <div className="thumb-img">
                                            <div className="media-img mediavideo-img">
                                            <img src={ media.image } alt="" onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
                                            </div>
                                            <div className="video-info">
                                            {media.media_time !=='' && (
                                            <div className="info-time">
                                                <p>{ media.media_time }</p>
                                            </div>
                                            )}
                                            <div className="info-share">
                                                <p className="first" data-for="share" data-tip="Click to Copy the Link" data-iscapture="true" onClick={ () => onShareOpenModal(media.media) }><span className="fa fa-share-alt"></span></p>
                
                                                <p className={ isMediaOnWish(media.id) ? 'second wished' : 'second'} onClick={addMedia} data-id={media.id}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" className="svg-inline--fa fa-heart fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                                </p>
                                            </div>
                                            <div className="playbtn">
                                                <div className="sonar-emitter" data-video={ media.media } onClick={onOpenModal}>
                                                    <div className="sonar-wave sonar-wave1"></div>
                                                    <div className="sonar-wave sonar-wave2"></div><span className="fa fa-play"></span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="thumb-content">
                                            <h5>{ media.title.length > 55 ? media.title.substring(0, 40)+ "..."  : media.title }</h5>
                                        </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
						</div>
                        ))}
					</div>
				</div>
			</div>
		</section>

    <Modal open={open} onClose={onCloseModal} center>
      <div className='player-wrapper' dangerouslySetInnerHTML={{__html: videourl }}>
      </div>
    </Modal>
	<Modal open={shareopen} onClose={onShareCloseModal} center>
      <div className='player-wrapper shareblock'>
	  	<a href={"https://api.whatsapp.com/send?text="+sharelink} target="_blank"><img src="/img/whatsapp.png" alt="" className="shareicon"/></a>
		<a href={"https://www.facebook.com/sharer/sharer.php?u="+sharelink} target="_blank"><img src="/img/facebook.png" alt="" className="shareicon"/></a>
      </div>
    </Modal>
    <ReactTooltip
      id="share"
      place='bottom'
      multiline={true}
    />
    <ToastContainer/>
    </div>
  );
};

export default Media;
