import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from 'react-validation/build/textarea';
import CheckButton from "react-validation/build/button";
import { isEmail, isNumeric } from "validator";
import { karmayogamevent, karmayogamfoundation, eventAllList } from "../actions/api-auth";
import { profileData } from "../actions/api-home";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const required = (value) => {
    var value = value.trim();
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  
  const validNumeric = (value) => {
    if (!isNumeric(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid Number.
        </div>
      );
    }
  };
  
  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

const KarmayogamHome = (props) => {
    const { isLoggedIn } = useSelector(state => state.authReducer);
    const form = useRef();
    const checkBtn = useRef();
    const [open, setOpen] = useState(false);
    const [videourl, setVideourl] = useState(''); 
    const [successful, setSuccessful] = useState(false);
    const formtwo = useRef();
    const checkBtnTwo = useRef();
    const [event, setEvent] = useState([]);
    const [successfultwo, setSuccessfulTwo] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [dialcode, setDialCode] = useState('');
    const [fullmobile, setFullMobile] = useState('');

    const profilestate = useSelector(state => state),
    { homeReducer } = profilestate,
    { profile_detail, eventall_lists } = homeReducer;

    const { message, msg_type } = useSelector(state => state.messageReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Karmayogam | Matangi";
        localStorage.removeItem("navtype");
        localStorage.removeItem("event_id");
        dispatch(profileData());
        dispatch(eventAllList());
    }, [dispatch]);

    useEffect(() => {
        setEvent(eventall_lists.data);
        setName(profile_detail.name);
        setEmail(profile_detail.email);
        setMobile(profile_detail.mobile);
        setDialCode(profile_detail.dial_code);
        if(profile_detail.mobile){
            setFullMobile('+'+profile_detail.dial_code+profile_detail.mobile);
        }
    }, [profile_detail, eventall_lists]);
    // -----------One section---------------
    const initialState = {
        event_id: "",
        message: "",
    };
    const [datas, setDatas] = useState(initialState);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
    
    const handleMobileChange = (status, phoneNumber, country) => {
        var ret = phoneNumber.replace('+'+country.dialCode,'');
        setMobile(ret);
        setDialCode(country.dialCode);
        setFullMobile(ret);
        console.log(country.dialCode);
        console.log(ret);
    };

    const handleContact = (e) => {
        if(!isLoggedIn) {
            localStorage.setItem("navtype", 'karma');
            props.history.push("/login");
        }else{
            e.preventDefault();
            setSuccessful(false);
            form.current.validateAll();
            if (checkBtn.current.context._errors.length === 0) {
                dispatch(karmayogamevent(datas))
                .then(() => {
                setSuccessful(true);
                })
                .catch(() => {
                setSuccessful(false);
                });
            }
        }
    };

    // -----------Two section---------------
    const initialStateTwo = {
        message: "",
    };
    const [twodatas, setTwoDatas] = useState(initialStateTwo);

    const handletwoInputChange = (event) => {
        const { name, value } = event.target;
        setTwoDatas({ ...twodatas, [name]: value });
    };
  
    const handleTwoContact = (e) => {
        if(!isLoggedIn) {
            localStorage.setItem("navtype", 'karma');
            props.history.push("/login");
        }else{
            e.preventDefault();
            setSuccessfulTwo(false);
            formtwo.current.validateAll();
            if (checkBtnTwo.current.context._errors.length === 0) {
                dispatch(karmayogamfoundation(twodatas))
                .then(() => {
                setSuccessfulTwo(true);
                })
                .catch(() => {
                setSuccessfulTwo(false);
                });
            }
        }
    };

    const onKarmaVideo = (e) => {
        var videlink = '<iframe width="560" height="315" src="https://www.youtube.com/embed/ETfjOj0TWk8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        setVideourl(videlink);
        setOpen(true);
    };

    const onCloseModal = () => setOpen(false);

    const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
        const program_id = localStorage.getItem("regprogram_id");
        if(program_id ==1){
            props.history.push("/ulchemy-feedback");
            window.location.reload();
        }
        if(program_id ==2){
            props.history.push("/ulchemyelite-feedback");
            window.location.reload();
        }
        if(program_id ==3){
            props.history.push("/divija-feedback");
            window.location.reload();
        }
        if(program_id ==4){
            props.history.push("/kaivalya-feedback");
            window.location.reload();
        }
        if(program_id ==5){
            props.history.push("/vision-feedback");
            window.location.reload();
        }
    }
    // if(!isLoggedIn) {
    //     localStorage.setItem("navtype", 'mandir');
    //     return <Redirect to="/login" />;
    // }
    // const userflag = localStorage.getItem("profile_status");
    // if(userflag ==1){
    //     alert("Please update Your Profile Details");
    //     props.history.push("/profile");
    //     window.location.reload();
    // }

  return (
    <section className="section mandir-sec">
			<div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ "marginBottom":"0px"}}>
                            <div className="mandir-title">
                                <h3>Karmayogam</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mandir-title">
                                <p>Karma Yoga means Selfless Action. To be a Karma Yogi (Volunteer) means to be in harmony with the existence. A true Karma Yogi is someone who can perform every action with a sense of devotion without being attached to its results. For a Karma Yogi, Life becomes a Leela, a Divine Play, so effortless and ecstatic.</p>
                                <p>Karma Yoga is not a service to somebody but, an action done with a sense of complete responsibility towards the whole existence.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center">
                                <img src="/img/karma.jpg" alt="" className="radious"/>
                                <div className="playbtn1" onClick={onKarmaVideo}>
                                    <div className="sonar-emitter">
                                        <div className="sonar-wave sonar-wave1"></div>
                                        <div className="sonar-wave sonar-wave2"></div><span className="fa fa-play"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="active">
                        <a href="#tab1" role="tab" data-toggle="tab">Event</a>
                        </li>
                        <li>
                        <a href="#tab2" role="tab" data-toggle="tab">Foundation</a>
                        </li>
                    </ul>
                    <div className="tab-content top-color-border">
                        <div className="tab-pane fade in active" id="tab1">
                        {!successful && (
                            <div className="login-right">
                                <h3>Karmayogam Registration</h3>
                                <Form onSubmit={handleContact} ref={form}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Name</label>
                                                <Input type="text" className="form-control" name="name" value={ name } onChange={handleInputChange} placeholder="Please enter name..." />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Email ID</label>
                                                <Input type="text" className="form-control" name="email" value={ email } onChange={handleInputChange} placeholder="Please enter email ID..." />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Phone Number</label>
                                                {/* <Input type="text" className="form-control" name="mobile" value={ mobile } onChange={handleMobileChange} placeholder="Please enter mobile..." /> */}
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control"
                                                    separateDialCode={true}
                                                    autoHideDialCode={true}
                                                    defaultCountry="in"
                                                    value={fullmobile}
                                                    allowDropdown={true}
                                                    onPhoneNumberChange={handleMobileChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Select Event <sup>*</sup></label>
                                                <Select name="event_id" className="form-control"  value={ datas.event_id }  onChange={handleInputChange} validations={[required]}>
                                                <option value="">Select Event</option>
                                                    { event && event.map((eventflag, evindex) => (
                                                    <option key={evindex} value={eventflag.id}>{eventflag.event_name} ({eventflag.event_address}) - {eventflag.event_date}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label> How do you wish to contribute?</label>
                                                <Textarea className="form-control" placeholder="Type your message..." value={ datas.message } onChange={handleInputChange} name="message" validations={[required]} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary loginbtn">{ isLoggedIn ? 'Submit' :'Please Login to proceed'}</button>
                                        </div>
                                    </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            {message && (
                                <div className="form-group">
                                <div className={"alert "+msg_type} role="alert">
                                    {message}
                                </div>
                                </div>
                            )}
                        </div>
                        )}

                        {successful &&(
                          <div className="login-right">
                          <h4>Registered Successfully</h4>
                          <p><Link to={"/"}>Back to Home</Link></p>
                          </div>
                        )}
                        </div>
                        <div className="tab-pane fade" id="tab2">
                            {!successfultwo && (
                            <div className="login-right">
                                <h3>Karmayogam Registration</h3>
                                <Form onSubmit={handleTwoContact} ref={formtwo}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> Name</label>
                                                <Input type="text" className="form-control" name="name" value={ name } onChange={handletwoInputChange} placeholder="Please enter name..." />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> Email ID</label>
                                                <Input type="text" className="form-control" name="email" value={ email } onChange={handletwoInputChange} placeholder="Please enter email ID..." />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> Phone Number</label>
                                                {/* <Input type="text" className="form-control" name="mobile" value={ mobile } onChange={handleMobileChange} placeholder="Please enter mobile..." /> */}
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control"
                                                    separateDialCode={true}
                                                    autoHideDialCode={true}
                                                    defaultCountry="in"
                                                    value={fullmobile}
                                                    allowDropdown={true}
                                                    onPhoneNumberChange={handleMobileChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label> How do you wish to contribute?</label>
                                                <Textarea className="form-control" placeholder="Type your message..." value={ twodatas.message } onChange={handletwoInputChange} name="message" validations={[required]} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary loginbtn">{ isLoggedIn ? 'Submit' :'Please Login to proceed'}</button>
                                        </div>
                                    </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtnTwo} />
                            </Form>
                            {message && (
                                <div className="form-group">
                                    <div className={"alert "+msg_type} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </div>
                        )}
                        
                        {successfultwo &&(
                          <div className="login-right">
                          <h4>Registered Successfully</h4>
                          <p><Link to={"/"}>Back to Home</Link></p>
                          </div>
                        )}
                        </div>
                    </div>
                    </div>
                    </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center>
            <div className='player-wrapper' dangerouslySetInnerHTML={{__html: videourl }}>
            </div>
            </Modal>
    </section>
  );
};

export default KarmayogamHome;
