import {
    RECENT_LIST,
    CATEGORY_LIST,
    CATEGORY_DETAIL,
    TAG_DETAIL,
    FILTER_GALLERY
  } from "./types";

  import { config } from './config.js';
  import authHeader from "./auth-header";
  import axios from 'axios';
  const BASEURL = config.BASEURL;
  
  export const recentList = () => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'recent/list', { headers: authHeader() });
      dispatch({
        type: RECENT_LIST,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const categoryList = () => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'category/list', { headers: authHeader() });
      dispatch({
        type: CATEGORY_LIST,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const categoryDetail = (id) => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'category/' + id, { headers: authHeader() });
      dispatch({
        type: CATEGORY_DETAIL,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const tagDetail = (id) => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'tag/' + id, { headers: authHeader() });
      dispatch({
        type: TAG_DETAIL,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const filterList = (startDate, endDate, searchtxt) => async (dispatch) => {
    try {
      const data = { "start_date": startDate, "end_date": endDate, "search_txt": searchtxt }
      const res = await axios.post(BASEURL + 'filter/gallery', data, { headers: authHeader() });
      dispatch({
        type: FILTER_GALLERY,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  
  
  
  