import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { logout } from "../actions/api-auth";
import { currentList } from "../actions/api-home";

const CurrentEvent = (props) => {
  const { isLoggedIn } = useSelector(state => state.authReducer);
  const [currentlist, setCurrentList] = useState([]);
  const currentstate = useSelector(state => state),
  { homeReducer } = currentstate,
  { current_lists } = homeReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Profile | Current Event";
		dispatch(currentList());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(current_lists.data)) {
			setCurrentList(current_lists.data);
		}
	}, [current_lists]);

  if(!isLoggedIn) {
    localStorage.setItem("navtype", 1);
    return <Redirect to="/login" />;
  }
  const userflag = localStorage.getItem("profile_status");
  if(userflag ==1){
    alert("Please update Your Profile Details");
    props.history.push("/profile");
    window.location.reload();
  }

  const feedcheck = localStorage.getItem("reg_id");
  if(feedcheck !=0){
    const program_id = localStorage.getItem("regprogram_id");
    if(program_id ==1){
      props.history.push("/ulchemy-feedback");
      window.location.reload();
    }
    if(program_id ==2){
      props.history.push("/ulchemyelite-feedback");
      window.location.reload();
    }
    if(program_id ==3){
      props.history.push("/divija-feedback");
      window.location.reload();
    }
    if(program_id ==4){
      props.history.push("/kaivalya-feedback");
      window.location.reload();
    }
    if(program_id ==5){
      props.history.push("/vision-feedback");
      window.location.reload();
    }
  }

  const logOut = (e) => {
    dispatch(logout());
  };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action active"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
              <div className="profile-head">
							  <h3>Current Event</h3>
              </div>
							<p></p>

              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Event Name</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Event Status</th>
                    <th>Price</th>
                    <th>Payable</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    { currentlist.length > 0 ? currentlist.map((currentdata, pindex) => (
                    <tr>
                      <td>{pindex +1}</td>
                      <td>{currentdata.event_name}</td>
                      <td>{currentdata.event_date}</td>
                      <td>{currentdata.event_address}</td>
                      { currentdata.event_status =='notstarted' &&
                         <td>Not Started</td>
                      }
                      { currentdata.event_status =='ongoing' &&
                         <td>On Going</td>
                      }
                      { currentdata.event_status =='completed' &&
                         <td>Completed</td>
                      }
                      { currentdata.event_status =='cancelled' &&
                         <td>Cancelled</td>
                      }
                      <td>{currentdata.currency} {currentdata.total}</td>
                      <td>{currentdata.currency} {currentdata.payable}</td>
                      <td>
                        {currentdata.pay_status =='success' && (<button className="btn btn-sm history-btn btn-success">Success</button>)}
                        {currentdata.pay_status =='pending' && (<button className="btn btn-sm history-btn btn-info">Pending</button> )}
                        {currentdata.pay_status =='failed' && (<button className="btn btn-sm history-btn btn-danger">Failed</button> )}
                        {currentdata.pay_status =='partialpay' && (<button className="btn btn-sm history-btn btn-success">Partial Pay</button> )}
                        {currentdata.pay_status =='paylater' && (<button className="btn btn-sm history-btn btn-warning">Pay Later</button> )}
                      </td>
                      <td>
                        {/* { currentdata.pay_status =='success' && currentdata.profile_info ==0 ? (
                        <span>
                        { currentdata.program_id ==1 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/ulchemy-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==2 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/ulchemyelite-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==3 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/divija-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==4 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/kaivalya-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==5 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/vision-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==6 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/shivratri-reg/"+currentdata.id}>Register</Link>
                        }
                        { currentdata.program_id ==8 &&
                          <Link className="btn btn-sm history-btn btn-info" to={"/paradesham-reg/"+currentdata.id}>Register</Link>
                        }
                        </span>
                        ): (
                          <Link className="btn btn-sm history-btn btn-primary" to={"/eventdetail/" + currentdata.id}>View</Link>
                        )} */}
                        <Link className="btn btn-sm history-btn btn-primary" to={"/eventdetail/" + currentdata.id}>View</Link>
                      </td>
                    </tr>
                    )) : (
                      <tr>
                        <td>No records found</td>
                      </tr>
                      )
                    }
                </tbody>
              </table>

						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default CurrentEvent;
