import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { config } from '../actions/config.js';
import authHeader from "../actions/auth-header";
import axios from 'axios';
const BASEURL = config.BASEURL;


const useWish = () => {
    const { user: currentUser } = useSelector((state) => state.authReducer);
    const [wishmedia, setWishMedia] = useState([]);
    const [wishevent, setWishEvent] = useState([]);

    const fetchWish = async () => {
        try {
            const res = await axios.get(BASEURL + 'wish/list', { headers: authHeader() });
            if(res.data.wishmedia){
                setWishMedia(res.data.wishmedia)
            }
            if(res.data.wishevent){
                setWishEvent(res.data.wishevent)
            }
        } catch (e) {
        
        }
    };

    const isMediaOnWish = (id) => !!wishmedia.find((item) => item == id);
    const isEventOnWish = (id) => !!wishevent.find((item) => item == id);

    const addToMediaWish = async (id) => {
        if(currentUser){
            if (isMediaOnWish(id)) {
                const data = {'id' : id};
                const res = await axios.post(BASEURL + 'wishmedia/remove', data, { headers: authHeader() });
                if(res.data.success ==1){
                    setWishMedia(res.data.wishmedia);
                    toast('Media Removed from favourite', { theme: "dark" });
                }
            } else {
                const data = {'id' : id};
                const res = await axios.post(BASEURL + 'wishmedia/add', data, { headers: authHeader() });
                if(res.data.success ==1){
                    setWishMedia(res.data.wishmedia);
                    toast('Media Added to favourite', { theme: "dark" });
                }
            }
        }else{
            toast('Please Login to update favourite', { theme: "dark" });
        }
    };
    
    const addToEventWish = async (id) => {
        if(currentUser){
            if (isEventOnWish(id)) {
                const data = {'id' : id};
                const res = await axios.post(BASEURL + 'wishevent/remove', data, { headers: authHeader() });
                if(res.data.success ==1){
                    setWishEvent(res.data.wishevent);
                    toast('Event Removed from favourite', { theme: "dark" });
                }
            } else {
                const data = {'id' : id};
                const res = await axios.post(BASEURL + 'wishevent/add', data, { headers: authHeader() });
                if(res.data.success ==1){
                    setWishEvent(res.data.wishevent);
                    toast('Event Added to favourite', { theme: "dark" });
                }
            }
        }else{
            toast('Please Login to update favourite', { theme: "dark" });
        }
    };

    useEffect(() => {
        fetchWish();
    }, []);

    return { isMediaOnWish, isEventOnWish, addToMediaWish, addToEventWish };
};

export default useWish;
