import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";

const Refund = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Refund Policy | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
  }, [dispatch]);
  
  return (
    <div>
    <section className="section widsomhome">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-sec">
              <h1 className="text-center">Refund and Cancellation Policy</h1>
            </div>
          </div>
            <div className="col-md-12 foundation">
                <p>Dakshina Foundation is instituting a refund and cancellation policy for the donors who have given donations to the Dakshina Foundation or have opted for any services, projects, programs for which a receipt for tax exemption has been issued.</p>
                <h4>Tax Exemption</h4>
                <p>All the payments paid to Dakshina Foundation Trust are eligible for an exemption (i.e. tax benefit) of 50%, under the section 80G(5)(vi) of Income Tax Act 1961 and are considered as donations. While opting for any service that Dakshina Foundation provides, under different projects or for different programs, a receipt for tax exemption will be issued to the donor.</p>
                <h4>Refund Policy</h4>
                <p>Dakshina Foundation is not obliged to make refunds and shall decline any requests for refund of donations, for which a tax exemption certificate has been issued. Any refund/cancellation for the donated amount by any donor will not be entertained, whether online, electronically or through any other means.</p>
                <p>No cash or refund of money shall be allowed. Once the donation is received for a cause, the same will not be refunded to the donor. No cancellations are allowed for any services, projects, programs or any other purpose for which the donation has been provided.</p>
                <p>Dakshina Foundation expects that all donors will exercise due care and diligence while making donations. Any request for cancellations and refund donations once duly placed, shall not be entertained under any circumstances.</p>
                <p>Dakshina Foundation</p>
                <p>Email: info@matangifoundation.org</p>
                <p>All decisions of Dakshina Foundation in this regard will be final and binding on the donor.</p>
            </div>
        </div>
      </div>
    </section>
    <div id="site-background">
        <div className="layer-wrapper">
            {/* <div className="layer1" style={{ "transform": "translateY(0px)" }}>
                <div className="element2"><img src="/img/element1.png" alt="" /></div>
            </div> */}
            <div class="layer2" style={{ "transform": "translateY(68.428px)" }}>
                <div class="element4"><img src="/img/element7.png" alt="" /></div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Refund;
