import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import Wave from 'react-wavify';
import { useSelector } from "react-redux";

const Footer = withRouter(({history}) => {

  const { user: currentUser } = useSelector((state) => state.authReducer);

  return (
    <div>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-menu">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/upcoming-events">Upcoming</Link></li>
                <li><Link to="/programs">Programs</Link></li>
                <li><Link to="/event-main">Events</Link></li>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/karmayogam-main">Karmayogam</Link></li>
                <li><Link to="/mandir-payment">Divine Blessings</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer-subscribe">
              <p><Link to="/" className="nav-link"><img src="/img/logo.png" alt="" /></Link></p>
              {/* <p>Subscribe to our newsletter</p>
              <div className="subscribe-form">
                <form action="">
                  <div className="form-wrapper">
                    <span className="form-control-wrap">
                      <input type="email" name="email" placeholder="Email" />
                    </span>
                    <button className="form-button"><span className="form-text">Send</span></button>
                  </div>
                </form>
              </div> */}
              <div className="social-groups">
                {/* <p><Link to="/"><span className="fa fa-twitter"></span></Link></p> */}
                <p><a href="https://www.facebook.com/Mithreshiva" target="_blank"><span className="fa fa-facebook"></span></a></p>
                <p><a href="https://www.instagram.com/guru.mithreshiva" target="_blank"><span className="fa fa-instagram"></span></a></p>
                <p><a href="https://www.youtube.com/ulchemyprogram" target="_blank"><span className="fa fa-youtube"></span></a></p>
              </div>
              
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-menu">
              <ul>
                <li><Link to="/gallery">Gallery</Link></li>
                {currentUser && (
                  <li><Link to="/profile">Account</Link></li>
                )}
                
                {!currentUser && (
                  <li><Link to="/login">Login</Link></li>
                )}
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/privacy-policy">Privacy policy</Link></li>
                <li><Link to="/refund-policy">Refund policy</Link></li>
                <li><Link to="/disclaimer">Disclaimer</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Wave fill='#2e3191'
        paused={false}
        options={{
          height: 10,
          amplitude: 100,
          speed: 0.15,
          points: 4
        }}
      />
    </footer>
    <call-us-selector phonesystem-url="https://ulchemycbe.3cx.in:5001" party="LiveChat810256"></call-us-selector>
    {/* <call-us style={{ "position": "fixed", "right": "20px", "bottom": "20px", "fontFamily": "Arial", "zIndex": "99999", "--call-us-form-header-background":"#002e7a", "--call-us-main-header-background":"#0596d4", "--call-us-client-text-color":"#d4d4d4","--call-us-agent-text-color":"#eeeeee","--call-us-form-height":"330px"}}
          id="wp-live-chat-by-3CX" 
          channel-url="https://ulchemycbe.3cx.in:5001" 
          files-url="https://ulchemycbe.3cx.in:5001" 
          minimized="true" 
          animation-style="none" 
          party="ashwini" 
          minimized-style="BubbleRight" 
          allow-call="false" 
          allow-video="false" 
          allow-soundnotifications="true" 
          enable-mute="true" 
          enable-onmobile="true" 
          offline-enabled="true" 
          enable="true" 
          ignore-queueownership="false" 
          authentication="both" 
          operator-name="Support" 
          show-operator-actual-name="true" 
          channel="phone" 
          aknowledge-received="true" 
          gdpr-enabled="false" 
          gdpr-message="I agree that my personal data to be processed and for the use of cookies in order to engage in a chat processed by COMPANY, for the purpose of Chat/Support for the time of  30 day(s) as per the GDPR." 
          message-userinfo-format="both" 
          message-dateformat="both" 
          start-chat-button-text="Chat" 
          window-title="Live Chat & Talk" 
          button-icon-type="Default" 
          invite-message="Matangi Foundation Welcomes you! Can we have your Name and Email ID ?" 
          authentication-message="Matangi Foundation Welcomes you! Can we have your Name and Email ID?" 
          unavailable-message="We are away, leave us a message!" 
          offline-finish-message="We received your message and we'll contact you soon." 
          ending-message="Your session is over. Please feel free to contact us again!" 
          greeting-visibility="none" 
          greeting-offline-visibility="none" 
          chat-delay="2000" 
          offline-name-message="Could we have your name?" 
          offline-email-message="Could we have your email?" 
          offline-form-invalid-name="I'm sorry, the provided name is not valid." 
          offline-form-maximum-characters-reached="Maximum characters reached" 
          offline-form-invalid-email="I'm sorry, that doesn't look like an email address. Can you try again?" 
          enable-direct-call="false" 
          enable-ga="false" 
          >
      </call-us> */}
    </div>
)})

export default Footer
