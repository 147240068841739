import {
  SLIDER_LIST,
  PROGRAM_LIST,
  PROJECT_LIST,
  EVENT_LIST,
  GLIMPSE_LIST,
  VIDEO_LIST,
  AUDIO_LIST,
  WISDOM_DETAIL,
  COUNTRY_LIST,
  STATE_LIST,
  CITY_LIST,
  SET_MESSAGE,
  PROFILE_DATA,
  CURRENT_LIST,
  HISTORY_LIST,
  PAYMENT_LIST,
  PAYMENTFAILED_LIST,
  REGISTER_DETAIL,
  FAVOURITE_LIST,
  LOGOUT,
  SET_FMESSAGE,
  DATA_FETCH,
  MANDIR_PAYLINK,
} from "./types";

import { config } from './config.js';
import authHeader from "./auth-header";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
const BASEURL = config.BASEURL;

export const sliderList = () => async (dispatch) => {
  try {

    const res = await axios.get(BASEURL + 'slider/list', { headers: authHeader() });
    // console.log(res);
    dispatch({
      type: SLIDER_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const MandirPayLink = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'mandir/paylink', { headers: authHeader() });
    dispatch({
      type: MANDIR_PAYLINK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const programList = () => async (dispatch) => {
  try {
    dispatch({
      type: DATA_FETCH,
      payload: false,
    });
    const res = await axios.get(BASEURL + 'program/list', { headers: authHeader() });
    dispatch({
      type: PROGRAM_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const projectList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'project/list', { headers: authHeader() });
    dispatch({
      type: PROJECT_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const eventList = () => async (dispatch) => {
  try {
    const res = await axios.post(BASEURL + 'event/list', { headers: authHeader() });
    dispatch({
      type:   EVENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const videoList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'video/list', { headers: authHeader() });
    dispatch({
      type:   VIDEO_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const audioList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'audio/list', { headers: authHeader() });
    dispatch({
      type:   AUDIO_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchUpdate = () => (dispatch) => {
  dispatch({
    type: DATA_FETCH,
    payload: false,
  });
};

export const wisdomDetail = (id) => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'wisdom/' + id, { headers: authHeader() });
    console.log(res.data);
    dispatch({
      type: WISDOM_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const countryList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'country/list', { headers: authHeader() });
    dispatch({
      type: COUNTRY_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const stateList = (id) => async (dispatch) => {
  try {
    const data = { "country_id": id}
    const res = await axios.post(BASEURL + 'state/list', data, { headers: authHeader() });
    dispatch({
      type: STATE_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const cityList = (id) => async (dispatch) => {
  try {
    const data = { "state_id": id}
    const res = await axios.post(BASEURL + 'city/list', data, { headers: authHeader() });
    dispatch({
      type: CITY_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const profileData = (id) => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'profile/detail', { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: PROFILE_DATA,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
    console.log(err);
  }
};

export const profileUpdate = (data) => async (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      var headers =  { 'Accept': 'application/json','Content-type': 'multipart/form-data', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': "Bearer " + user.access_token };
    } else {
      var headers =  { 'Content-type': 'multipart/form-data', 'X-Requested-With': 'XMLHttpRequest'};
    }
    const res = await axios.post(BASEURL + 'profile/update', data, { headers: headers });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    if(res.data.success ===1){
      dispatch({
        type: SET_MESSAGE,
        payload: "Updated Successfully.",
      });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const currentList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'current/list', { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: CURRENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const historyList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'history/list', { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: HISTORY_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const registerDetail = (id) => async (dispatch) => {
  try {
    const data = { "register_id": id}
    const res = await axios.post(BASEURL + 'register/detail', data, { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: REGISTER_DETAIL,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    console.log(err);
  }
};

export const paymentList = (search) => async (dispatch) => {
  try {
    const data = { "search": search}
    const res = await axios.post(BASEURL + 'payment/list', data, { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    
    dispatch({
      type: PAYMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const paymentFailedList = (search) => async (dispatch) => {
  try {
    const data = { "search": search}
    const res = await axios.post(BASEURL + 'paymentfailed/list', data, { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: PAYMENTFAILED_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const messageUpdate = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });
};

export const glimpseList = (id) => async (dispatch) => {
  try {
    const data = { "program_id": id}
    const res = await axios.post(BASEURL + 'glimpse/list', data, { headers: authHeader() });
    dispatch({
      type: GLIMPSE_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const favouriteList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'favourite/list', { headers: authHeader() });
    if(res.data.success ===2){
      localStorage.removeItem("user");
      localStorage.removeItem("navtype");
      localStorage.removeItem("event_id");
      localStorage.removeItem("reg_id");
      localStorage.removeItem("regprogram_id");
      dispatch({
        type: LOGOUT,
      });
      <Redirect to="/login" />
    }
    dispatch({
      type: FAVOURITE_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};