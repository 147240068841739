import {
  SLIDER_LIST,
  PROGRAM_LIST,
  PROJECT_LIST,
  EVENT_LIST,
  EVENTALL_LIST,
  VIDEO_LIST,
  AUDIO_LIST,
  WISDOM_DETAIL,
  COUNTRY_LIST,
  STATE_LIST,
  CITY_LIST,
  PROFILE_DATA,
  CURRENT_LIST,
  HISTORY_LIST,
  PAYMENT_LIST,
  PAYMENTFAILED_LIST,
  REGISTER_DETAIL,
  GLIMPSE_LIST,
  FAVOURITE_LIST,
  DATA_FETCH,
  MANDIR_PAYLINK
} from "../actions/types";

const initialState = {
  slider_lists: [],
  program_lists: [],
  project_lists: [],
  event_lists: [],
  eventall_lists: [],
  video_lists: [],
  audio_lists: [],
  country_lists: [],
  state_lists: [],
  city_lists: [],
  wisdom_detail: '',
  profile_detail: '',
  mandir_paylink:'',
  current_lists: [],
  history_lists: [],
  payment_lists: [],
  paymentfailed_lists: [],
  glimpse_lists: [],
  register_detail: '',
  favourite_lists:'',
  datafetched: false,
  loading: false,
  message: '',
  isDeleted: false
};


const homeReducer = (state = initialState, action) => {

  switch (action.type) {
    case SLIDER_LIST:
      return {
        ...state,
        slider_lists: action.payload
        
      };
    case MANDIR_PAYLINK:
      return {
        ...state,
        mandir_paylink: action.payload.data
      };
    case DATA_FETCH:
      return {
        ...state,
        datafetched: false
      };
    case PROGRAM_LIST:
      return {
        ...state,
        program_lists: action.payload
        
      };
    case PROJECT_LIST:
      return {
        ...state,
        project_lists: action.payload
        
      };
    case EVENT_LIST:
      return {
        ...state,
        event_lists: action.payload
        
      };
    case EVENTALL_LIST:
      return {
        ...state,
        eventall_lists: action.payload
        
      };
    case GLIMPSE_LIST:
      return {
        ...state,
        glimpse_lists: action.payload
        
      };
    case VIDEO_LIST:
      return {
        ...state,
        video_lists: action.payload
        
      };
    case AUDIO_LIST:
      return {
        ...state,
        audio_lists: action.payload
        
      };
    case WISDOM_DETAIL:
      return {
        ...state,
        wisdom_detail: action.payload,
        datafetched: true
      };
    case COUNTRY_LIST:
      return {
        ...state,
        country_lists: action.payload
        
      };
    case STATE_LIST:
      return {
        ...state,
        state_lists: action.payload
        
      };
    case CITY_LIST:
      return {
        ...state,
        city_lists: action.payload
        
      };
    case PROFILE_DATA:
      return {
        ...state,
        profile_detail: action.payload
        
      };
    case CURRENT_LIST:
      return {
        ...state,
        current_lists: action.payload
        
      };
    case HISTORY_LIST:
      return {
        ...state,
        history_lists: action.payload
        
      };
    case PAYMENT_LIST:
      return {
        ...state,
        payment_lists: action.payload
        
      };
    case PAYMENTFAILED_LIST:
      return {
        ...state,
        paymentfailed_lists: action.payload
        
      };
    case REGISTER_DETAIL:
      return {
        ...state,
        register_detail: action.payload
        
      };
    case FAVOURITE_LIST:
      return {
        ...state,
        favourite_lists: action.payload
        
      };
    default:
      return state;
  }
};

export default homeReducer;
