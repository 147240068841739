import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { logout } from "../actions/api-auth";
import { feedbackList } from "../actions/api-wisdom";

const FeedbackView = (props) => {
    const { id } = useParams();
    
    const { isLoggedIn } = useSelector(state => state.authReducer);
    const [feedbacklist, setFeedBackList] = useState('');

    const feedstate = useSelector(state => state),
        { wisdomReducer } = feedstate,
        { feedback_list } = wisdomReducer;

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Feedback Detail | Event Detail";
            dispatch(feedbackList(id));
        }, [dispatch]);

	useEffect(() => {
		if (!isEmpty(feedback_list)) {
			setFeedBackList(feedback_list);
            console.log(feedbacklist);
		}
        
	}, [feedback_list]);


  if(!isLoggedIn) {
    localStorage.setItem("navtype", 1);
    return <Redirect to="/login" />;
  }

  const logOut = (e) => {
    dispatch(logout());
  };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
                            <div className="profile-head">
                                <h3>Feedback Detail <Link to="/history-event" className="btn btn-success history-btn btn-sm">Back</Link> </h3>
                            </div>
                            
                            <div className="detailblock" style={{'width':"90%"}}>
                                {feedbacklist.program_id ==1 && 
                                <div>
                                    <p><b>Reason for attending the program? </b><br/> {feedbacklist.reason_attend}</p>
                                    <p><b>Do you get what you expected? </b><br/>  {feedbacklist.expected}</p>
                                    <p><b>Mention 3 things which you have learnt from the program? </b><br/> {feedbacklist.learn_program} </p>
                                    <p><b>Are you willing to follow the practices you have learnt from the program?</b><br/> {feedbacklist.practice_program} </p>
                                    <p><b>Would you like to participate from upcoming programs?</b><br/> {feedbacklist.participate_upcoming} </p>
                                    <p><b>Do you wish to volunteer for Ulchemy programs?</b><br/> {feedbacklist.wish_volunteer} </p>
                                    <p><b>Do you have anything to say about this program ?</b><br/> {feedbacklist.about_program} </p>
                                </div>
                                }

                                {feedbacklist.program_id ==2 && 
                                <div>
                                    <p><b>Reason for attending the program? </b><br/> {feedbacklist.reason_attend}</p>
                                    <p><b>Do you get what you expected? </b><br/>  {feedbacklist.expected}</p>
                                    <p><b>Mention 3 things which you have learnt from the program? </b><br/> {feedbacklist.learn_program} </p>
                                    <p><b>Are you willing to follow the practices you have learnt from the program?</b><br/> {feedbacklist.practice_program} </p>
                                    <p><b>Would you like to participate from upcoming programs?</b><br/> {feedbacklist.participate_upcoming} </p>
                                    <p><b>Do you wish to volunteer for Ulchemy programs?</b><br/> {feedbacklist.wish_volunteer} </p>
                                    <p><b>Do you have anything to say about this program ?</b><br/> {feedbacklist.about_program} </p>
                                </div>
                                }

                                {feedbacklist.program_id ==3 && 
                                <div>
                                    <p><b>What Have you understand about life(Everything) after the Dvija plus experience? </b><br/> {feedbacklist.experience}</p>
                                    <p><b>What was your goal in life before the start of the program? </b><br/>  {feedbacklist.goal_before}</p>
                                    <p><b>What is your goal in life now after undergoing this program? </b><br/> {feedbacklist.goal_after} </p>
                                    <p><b>Mention 3 important things, which you cannot forget from the program?</b><br/> {feedbacklist.things_notforget} </p>
                                    <p><b>Will you recommend this program for your friends and or relatives?</b><br/> {feedbacklist.recommend_program} </p>
                                    <p><b>Do you wish to come for kaivalya 8 day program?</b><br/> {feedbacklist.wish_kaivalya} </p>
                                    <p><b>Do you Have anything else to say about this program?</b><br/> {feedbacklist.about_program} </p>
                                </div>
                                }

                                {feedbacklist.program_id ==4 && 
                                <div>
                                    <p><b>Matrial Status....If yes, does your spouse attend Kaivalya? </b><br/> {feedbacklist.spouse_attend}</p>
                                    <p><b>Mention 3 important things which you have experienced in kaivalya ? </b><br/>  {feedbacklist.experience}</p>
                                    <p><b>What you want in life? </b><br/> {feedbacklist.want_life} </p>
                                    <p><b>How do you want to be a part this mission of taking?</b><br/> {feedbacklist.mission_taking} </p>
                                    <p><b>Do you want to share anything else about kaivalya?</b><br/> {feedbacklist.about_program} </p>
                                </div>
                                }

                                {feedbacklist.program_id ==5 && 
                                <div>
                                    <p><b>Reason for attending the program? </b><br/> {feedbacklist.reason_attend}</p>
                                    <p><b>Do you get what you expected? </b><br/>  {feedbacklist.expected}</p>
                                    <p><b>Mention 3 things which you have learnt from the program? </b><br/> {feedbacklist.learn_program} </p>
                                    <p><b>Are you willing to follow the practices you have learnt from the program?</b><br/> {feedbacklist.practice_program} </p>
                                    <p><b>Would you like to participate from upcoming programs?</b><br/> {feedbacklist.participate_upcoming} </p>
                                    <p><b>Do you wish to volunteer for Ulchemy programs?</b><br/> {feedbacklist.wish_volunteer} </p>
                                    <p><b>Do you have anything to say about this program ?</b><br/> {feedbacklist.about_program} </p>
                                </div>
                                }

                            </div>
                            
						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default FeedbackView;
