import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  SET_FMESSAGE,
  EVENTALL_LIST,
} from "./types";
import { config } from './config.js';
import authHeader from "./auth-header";
import axios from 'axios';
const BASEURL = config.BASEURL;

export const register = (data) => async (dispatch) => {
  try {
    let headers = { 'Accept': 'application/json','Content-Type': 'multipart/form-data', 'X-Requested-With': 'XMLHttpRequest'}
    const res = await axios.post(BASEURL + 'register', data, { headers: headers });
    if(res.data.success ===1){
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: "Registered Successfully. Please Login",
      });

      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("profile_status", res.data.profile_status);
      localStorage.setItem("reg_id", res.data.reg_id);
      localStorage.setItem("regprogram_id", res.data.regprogram_id);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      
      return Promise.resolve();
    }else{
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: REGISTER_FAIL,
    });
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const login = (username, password) => async (dispatch) => {
  try {
    const data = { "username": username, "password": password }
    const res = await axios.post(BASEURL + 'login', data, { headers: authHeader() });
    if(res.data.success ===1){
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("profile_status", res.data.profile_status);
      localStorage.setItem("reg_id", res.data.reg_id);
      localStorage.setItem("regprogram_id", res.data.regprogram_id);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve();
    }else{
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("navtype");
  localStorage.removeItem("event_id");
  localStorage.removeItem("reg_id");
  localStorage.removeItem("regprogram_id");
  localStorage.removeItem("profile_status");
  dispatch({
    type: LOGOUT,
  });
};

export const contact = (data) => async (dispatch) => {
  try {
    const res = await axios.post(BASEURL + 'contact', data, { headers: authHeader() });
    if(res.data.success ===1){
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const karmayogamevent = (data) => async (dispatch) => {
  try {
    const res = await axios.post(BASEURL + 'karmayogam/event', data, { headers: authHeader() });
    if(res.data.success ===1){
      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: res.data.message,
      // });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const karmayogamfoundation = (data) => async (dispatch) => {
  try {
    const res = await axios.post(BASEURL + 'karmayogam/foundation', data, { headers: authHeader() });
    if(res.data.success ===1){
      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: res.data.message,
      // });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const eventAllList = () => async (dispatch) => {
  try {
    const res = await axios.get(BASEURL + 'eventall/list', { headers: authHeader() });
    dispatch({
      type:   EVENTALL_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const forgot = (mobile) => async (dispatch) => {
  try {
    const data = { "mobile": mobile}
    const res = await axios.post(BASEURL + 'forgot/password', data, { headers: authHeader() });
    if(res.data.success ===1){
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};

export const resetpassword = (data) => async (dispatch) => {
  try {
    const res = await axios.post(BASEURL + 'reset/password', data, { headers: authHeader() });
    if(res.data.success ===1){
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      return Promise.resolve();
    }else{
      dispatch({
        type: SET_FMESSAGE,
        payload: res.data.message,
      });
      return Promise.reject();
    }
  } catch (err) {
    const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
    dispatch({
      type: SET_FMESSAGE,
      payload: message,
    });
    return Promise.reject();
  }
};