import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'
import { isEmpty } from 'lodash';
import { recentList, categoryList, filterList } from "../actions/api-gallery";
import { SRLWrapper  } from "simple-react-lightbox";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
const Gallery = (props) => {
	
	const dispatch = useDispatch();
  	const gallery = useSelector(state => state),
    { galleryReducer } = gallery,
    { recent_lists, category_lists, filter_gallery } = galleryReducer;
	const options = {
		buttons: {
		  showDownloadButton: false,
		}
	}
	
	const [recent,setRecent]=useState([]);
	const [category,setCategory]=useState([]);
	const [filtergallery, setFilterGallery] = useState([]);
	const [filtermsg, setFilterMsg] = useState('Search result...');
	const [tags,setTag]=useState([]);
	const [submitted, setSubmitted] = useState(false);
	const initialState = {
		startDate: "",
		endDate: "",
		searchtxt: ""
	  };
	const [filters, setFilters] = useState(initialState);
	useEffect(() => {
		document.title = "Gallery | Matangi";
		localStorage.removeItem("navtype");
  		localStorage.removeItem("event_id");
		dispatch(recentList());
		dispatch(categoryList());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(recent_lists.data)) {
			setRecent(recent_lists.data);
		}
		if (!isEmpty(category_lists.data)) {
			setCategory(category_lists.data);
		}
		if (!isEmpty(recent_lists.tags)) {
			setTag(recent_lists.tags);
		}
		if (!isEmpty(filter_gallery)) {
			if(filter_gallery.success ===0){
				setFilterMsg(filter_gallery.message);
				setFilterGallery([]);
			}else{
				setFilterMsg('Search result...');
				if (!isEmpty(filter_gallery.data)) {
					setFilterGallery(filter_gallery.data);
				}
			}
		}
	}, [recent_lists,category_lists, filter_gallery]);

	function validate(startDate, endDate, searchtxt) {
		if((startDate ==='' || startDate ===null) && (endDate ==='' || endDate ===null) && searchtxt ==='') {
			return false
		}
		else {
			return true;
		}
	}

	const handleInputChange = event => {
		setFilters({ ...filters, ['searchtxt']: event.target.value });
	};

	const setStart = (date) => {
    	setFilters({ ...filters, ['startDate']: date });
	};

	const setEnd = (date) => {
		setFilters({ ...filters, ['endDate']: date });
	};

	const filterAlbum = () => {
		const { startDate, endDate, searchtxt } = filters;
		const errors = validate(startDate, endDate, searchtxt);
		if(errors) {
			dispatch(filterList(startDate, endDate, searchtxt));
			setSubmitted(true);
		}
		else {
			setFilterGallery([]);
			setFilters(initialState);
			setSubmitted(false);
			dispatch(recentList());
		}
	};

	const filterReset = () => {
		setFilterGallery([]);
		setFilters(initialState);
		setSubmitted(false);
		dispatch(recentList());
	};

	
	const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
    }
  return (
    <div>
        <section className="section gallerytop">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="mainnavigation text-center">
							<Link to="/gallery" className="buttondangerclass">Image Callery</Link>
							<Link to="/media" className="buttonclass">AV Gallery</Link>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							
							<div className="col-md-2">
								<h4>Date</h4>
							</div>
							<div className="col-md-4">
							{/* <DatePicker maxDate={new Date()} selected={filters.startDate} onChange={(date) => setStart(date)} className="form-control" dateFormat="dd/MM/yyyy" placeholderText='Start Date'/> */}
							<DatePickerInput
								maxDate={new Date()}
								className='my-react-datepicker'
								value={filters.startDate}
								showOnInputClick
								displayFormat='DD/MM/YYYY'
								returnFormat='YYYY-MM-DD'
								placeholder='Start Date'
								onChange={(jsDate) => setStart(jsDate)}
							/>
							</div>
							<div className="col-md-4">
							{/* <DatePicker maxDate={new Date()} selected={filters.endDate} onChange={(date) => setEnd(date)} className="form-control" dateFormat="dd/MM/yyyy" placeholderText='End Date'/> */}
							<DatePickerInput
								maxDate={new Date()}
								className='my-react-datepicker'
								value={filters.endDate}
								showOnInputClick
								displayFormat='DD/MM/YYYY'
								returnFormat='YYYY-MM-DD'
								placeholder='End Date'
								onChange={(jsDate) => setEnd(jsDate)}
							/>
							</div>
							<div className="col-md-2">
								<button className="buttonclass" onClick={filterAlbum}>Go</button>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-6">
								<input type="text" className="form-control" value={ filters.searchtxt } onChange={handleInputChange} placeholder="Search..." />
							</div>
							<div className="col-md-5">
								<button className="buttonclass" onClick={filterAlbum}>Go</button>
								<button className="buttondangerclass" onClick={filterReset}>Reset</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
        <section className="section-recent">
			<div className="container">
				<div className="row">
				{submitted ? (
					<div className="col-md-12">
						<p>{filtermsg}</p>
					</div>
				) : (
					<div className="col-md-12">
						<div className="title-sec">
						<h1 className="text-center">Recent</h1>
						</div>
					</div>
				)}
					<div className="col-md-12">
					<SRLWrapper options={options}>
						<div id="content-page-one">
							<div className="row">
						{submitted ? (
							filtergallery && filtergallery.map((album, findex) => (
								<div className="col-lg-2 col-md-2 col-sm-6" key={findex}>
									<div className="vertical-item gallery-item content-absolute text-center">
										<div className="item-media">
											<img src={ album.image } alt={ album.caption +' '+album.album_date}  onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
										</div>
									</div>
								</div>
							))
							) :
							<>
								{ recent && recent.map((recent, index) => {
									if(index == 0)
									 return <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
										<div className="vertical-item gallery-item content-absolute text-center" style={{"height": "267px"}}>
											<div className="item-media">
												<img src={ recent.image } alt={ recent.caption +' '+recent.album_date} onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
											</div>
										</div>
									</div>
									})}
								<div className="col-lg-8 col-md-8">
								{ recent && recent.map((recent, sindex) => {
									if(sindex != 0)
									 return <div className="col-lg-3 col-md-4 col-sm-6" key={sindex}>
										<div className="vertical-item gallery-item content-absolute text-center">
											<div className="item-media">
												<img src={ recent.image } alt={ recent.caption +' '+recent.album_date} onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
											</div>
										</div>
									</div>
									})}
								</div>
								</>
							}

							</div>
						</div>
						</SRLWrapper>
					</div>
					<div className="col-md-12">
						<div className="taglist">
						<ul>
							{ tags && tags.map((tag, tagindex) => (
							<li key={tagindex}><span className="green-sm-dot"></span> <Link to={"/tag/"+tag.id}>{tag.album_tag}</Link></li>
							))}
						</ul>	
						</div>	
					</div>
				</div>
			</div>
		</section>


		<section className="section-album">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className="title-sec">
							<h1 className="text-center">Albums</h1>
						</div>
					</div>
					
					<div className="col-sm-12">
						<div className="row">
						<SRLWrapper>
						{ category && category.map((category, catindex) => (	
							<div className="col-sm-4" key={catindex}>
								<div className="album-box">
									<div className="row">
										<div className="col-sm-12">
											<div className="album-title">
												<h4>{ category.album_category.length > 17 ? category.album_category.substring(0, 17)+ "..."  : category.album_category }</h4>
												<p><Link to={"/category/" + category.id} className="viewclass">VIEW ALL</Link></p>
											</div>
										</div>
										{ category.albums && category.albums.map((album, albumindex, array) => {
											if (array.length - 1 === albumindex) {
												return (<div className="col-sm-6" key={albumindex}>
													<div className="vertical-item gallery-item content-absolute text-center">
														<div className="item-media">
															<img src={ album.image } alt={ album.caption } onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
															<div className="plus-links">
																<div className="plus-wrap">
																<Link to={"/category/" + category.id}>+{category.album_count} <br/><span>View All</span></Link>
																</div>
															</div>
														</div>
													</div>
												</div>);
											} else {
												return (<div className="col-sm-6" key={albumindex}>
													<div className="vertical-item gallery-item content-absolute text-center">
														<div className="item-media">
															<img src={ album.image } alt={ album.caption } onError={(e)=>{e.target.src='/img/placeholder.png'}}/>

														</div>
													</div>
												</div>); 
											}
										})
										}
										
									</div>
								</div>
							</div>
						))
					}
					</SRLWrapper>
						</div>
					</div>
				</div>
			</div>
		</section>

    </div>
  );
};

export default Gallery;
