import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { isEmpty } from 'lodash';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';

import { register } from "../actions/api-auth";
import { countryList, stateList, cityList } from "../actions/api-home";
import 'react-responsive-modal/styles.css';
import SelectSearch, { fuzzySearch } from 'react-select-search';
// import { DatePicker, DatePickerInput } from 'rc-datepicker';
// import 'rc-datepicker/lib/style.css';

import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import moment from 'moment';
import Compressor from 'compressorjs';

const required = (value) => {
  var value = value.trim();
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
  
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const vmobile = (value) => {
  if (value.length < 10 || value.length > 13 || isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Mobile number
      </div>
    );
  }
  if(isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Mobile number
      </div>
    );
  }
};

const vmobileoptional = (value) => {
  if(value.length > 0){
    if (value.length < 10 || value.length > 13 || isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
    if(isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
  }
};

const vnumber = (value) => {
  if(isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Number
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const [open, setOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [salut, setSalut] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [dialcode, setDialCode] = useState('');
  const [fullmobile, setFullMobile] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [picture, setPicture] = useState('');
  const [marital, setMarital] = useState('Married');
  const [countryid, setCountryId] = useState('');
  const [stateid, setStateId] = useState('');
  const [cityid, setCityId] = useState('');
  const [street, setStreet] = useState('');
  const [pincode, setPincode] = useState('');
  const [education, setEducation] = useState('');
  const [profession, setProfession] = useState('');
  const [professiondetail, setProfessionDetail] = useState('');
  const [company, setCompany] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [pan, setPan] = useState('');
  const [knowmatangi, setKnowMatangi] = useState('');
  const [friendname, setFriendName] = useState('');
  const [friendmobile, setFriendMobile] = useState('');
  const [knowother, setKnowOther] = useState('');

  const [accept, setAccept] = useState(false);
  const [acceptmsg, setAcceptmsg] = useState(false);

  const { message, msg_type } = useSelector(state => state.messageReducer);
  const registerstate = useSelector(state => state),
  { homeReducer } = registerstate,
  { country_lists, state_lists, city_lists } = homeReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Register | Matangi";
		dispatch(countryList());
    if(sessionStorage.getItem("country") !=null){
      setCountryId(parseInt(sessionStorage.getItem("country")));
      dispatch(stateList(parseInt(sessionStorage.getItem("country")))); 
    }
    if(sessionStorage.getItem("state") !=null){
      dispatch(cityList(parseInt(sessionStorage.getItem("state"))));
      setStateId(parseInt(sessionStorage.getItem("state")));
    }
    if(sessionStorage.getItem("city") !=null){
      setCityId(parseInt(sessionStorage.getItem("city")));
    }

    if(sessionStorage.getItem("salut") !=null){
      setSalut(sessionStorage.getItem("salut"));
    }
    if(sessionStorage.getItem("name") !=null){
      setName(sessionStorage.getItem("name"));
    }
    if(sessionStorage.getItem("email") !=null){
      setEmail(sessionStorage.getItem("email"));
    }
    if(sessionStorage.getItem("mobile") !=null){
      setMobile(parseInt(sessionStorage.getItem("mobile")));
    }
    if(sessionStorage.getItem("dialcode") !=null){
      setDialCode(parseInt(sessionStorage.getItem("dialcode")));
    }
    if(sessionStorage.getItem("fullmobile") !=null){
      setFullMobile('+'+parseInt(sessionStorage.getItem("dialcode"))+parseInt(sessionStorage.getItem("fullmobile")));
    }
    if(sessionStorage.getItem("gender") !=null){
      setGender(sessionStorage.getItem("gender"));
    }
    if(sessionStorage.getItem("marital") !=null){
      setMarital(sessionStorage.getItem("marital"));
    }
    if(sessionStorage.getItem("dob") !=null){
      setDob(new Date(Date.parse(sessionStorage.getItem("dob"))));
    }
    if(sessionStorage.getItem("street") !=null){
      setStreet(sessionStorage.getItem("street"));
    }
    if(sessionStorage.getItem("pincode") !=null){
      setPincode(sessionStorage.getItem("pincode"));
    }
    if(sessionStorage.getItem("education") !=null){
      setEducation(sessionStorage.getItem("education"));
    }
    if(sessionStorage.getItem("profession") !=null){
      setProfession(sessionStorage.getItem("profession"));
    }
    if(sessionStorage.getItem("professiondetail") !=null){
      setProfessionDetail(sessionStorage.getItem("professiondetail"));
    }
    if(sessionStorage.getItem("whatsapp") !=null){
      setWhatsapp(sessionStorage.getItem("whatsapp"));
    }
    if(sessionStorage.getItem("pan") !=null){
      setPan(sessionStorage.getItem("pan"));
    }
    if(sessionStorage.getItem("company") !=null){
      setCompany(sessionStorage.getItem("company"));
    }
    if(sessionStorage.getItem("knowmatangi") !=null){
      setKnowMatangi(sessionStorage.getItem("knowmatangi"));
    }
    if(sessionStorage.getItem("friendname") !=null){
      setFriendName(sessionStorage.getItem("friendname"));
    }
    if(sessionStorage.getItem("friendmobile") !=null){
      setFriendMobile(sessionStorage.getItem("friendmobile"));
    }
    if(sessionStorage.getItem("knowother") !=null){
      setKnowOther(sessionStorage.getItem("knowother"));
    }
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(country_lists.data)) {
      const countries = [];
        const docs =country_lists.data;
        docs.forEach((snap) => {
          if(snap != null){
            const data=  {'name':snap.name,'value':snap.id};
            countries.push(data);
          }
      });
      setCountry(countries);
		}
    if (!isEmpty(state_lists.data)) {
      const states = [];
        const docs =state_lists.data;
        docs.forEach((snap) => {
          if(snap != null){
            const data=  {'name':snap.name,'value':snap.id};
            states.push(data);
          }
      });
			setState(states);
		}
    if (!isEmpty(city_lists.data)) {
      const cities = [];
        const docs =city_lists.data;
        docs.forEach((snap) => {
          if(snap != null){
            const data=  {'name':snap.name,'value':snap.id};
            cities.push(data);
          }
      });
			setCity(cities);
		}
	}, [country_lists, state_lists, city_lists]);

  const handleAccept = (event) => {
    const checked = event.target.checked;
    if (checked) {
        setAccept(true);
    } else {
        setAccept(false);
    }
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setDatas({ ...datas, [name]: value });
  //   if(name ==='country_id'){
  //     dispatch(stateList(event.target.value));
  //   }
  //   if(name ==='state_id'){
  //     dispatch(cityList(event.target.value));
  //   }
  // };
  const  handleSalutChange = (event) => {
    setSalut(event.target.value);
    sessionStorage.setItem("salut", event.target.value);
  }
  const  handleNameChange = (event) => {
    setName(event.target.value);
    sessionStorage.setItem("name", event.target.value);
  }
  const  handleEmailChange = (event) => {
    setEmail(event.target.value);
    sessionStorage.setItem("email", event.target.value);
  }
  const  handlePasswordChange = (event) => {
    setPassword(event.target.value);
    sessionStorage.setItem("password", event.target.value);
  }
  const  handlePictureChange = (event) => {
    const image = event.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      maxWidth:200,
      maxHeight:200,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setPicture(compressedResult);
      },
    });

  }

  const handleMobileChange = (status, phoneNumber, country) => {
    var ret = phoneNumber.replace('+'+country.dialCode,'');
    setMobile(ret);
    setDialCode(country.dialCode);
    setFullMobile(ret);
    sessionStorage.setItem("mobile", ret);
    sessionStorage.setItem("dialcode", country.dialCode);
    sessionStorage.setItem("fullmobile", ret);
  };

  const  handleGenderChange = (event) => {
    setGender(event.target.value);
    sessionStorage.setItem("gender", event.target.value);
  }
  const  handleMaritalChange = (event) => {
    setMarital(event.target.value);
    sessionStorage.setItem("marital", event.target.value);
  }
  const  handleStreetChange = (event) => {
    setStreet(event.target.value);
    sessionStorage.setItem("street", event.target.value);
  }
  const  handlePincodeChange = (event) => {
    setPincode(event.target.value);
    sessionStorage.setItem("pincode", event.target.value);
  }
  const  handleEducationChange = (event) => {
    setEducation(event.target.value);
    sessionStorage.setItem("education", event.target.value);
  }
  const  handleProfessionChange = (event) => {
    setProfession(event.target.value);
    sessionStorage.setItem("profession", event.target.value);
  }
  const  handleProfessionDetailChange = (event) => {
    setProfessionDetail(event.target.value);
    sessionStorage.setItem("professiondetail", event.target.value);
  }
  const  handleCompanyChange = (event) => {
    setCompany(event.target.value);
    sessionStorage.setItem("company", event.target.value);
  }
  const  handlePanChange = (event) => {
    setPan(event.target.value);
    sessionStorage.setItem("pan", event.target.value);
  }
  const  handleWhatsappChange = (event) => {
    setWhatsapp(event.target.value);
    sessionStorage.setItem("whatsapp", event.target.value);
  }
  const  handleCountryChange = (optionSelected) => {
    dispatch(stateList(optionSelected));
    setCountryId(optionSelected);
    sessionStorage.setItem("country", optionSelected);
  }
  const  handleStateChange = (optionSelected) => {
    dispatch(cityList(optionSelected));
    setStateId(optionSelected);
    sessionStorage.setItem("state", optionSelected);
  }
  const  handleCityChange = (optionSelected) => {
    setCityId(optionSelected);
    sessionStorage.setItem("city", optionSelected);
  }

  const  handleKnowMatangi = (event) => {
    setKnowMatangi(event.target.value);
    sessionStorage.setItem("knowmatangi", event.target.value);
  }
  const  handleFriendName = (event) => {
    setFriendName(event.target.value);
    sessionStorage.setItem("friendname", event.target.value);
  }
  const  handleFriendMobile = (event) => {
    setFriendMobile(event.target.value);
    sessionStorage.setItem("friendmobile", event.target.value);
  }
  const  handleKnowOther = (event) => {
    setKnowOther(event.target.value);
    sessionStorage.setItem("knowother", event.target.value);
  }

  // const handleMobileChange = (status, phoneNumber, country) => {
  //   setMobile(phoneNumber);
  //   setDialCode(country.dialCode);
  //   console.log(phoneNumber)
  //   setDatas({ ...datas, ['mobile']: phoneNumber });
  //   setDatas({ ...datas, ['dial_code']: country.dialCode });
  //   console.log(datas)
  // };

  // const handleCountryChange = (optionSelected) => {
  //   setDatas({ ...datas, ['country_id']: optionSelected });
  //   dispatch(stateList(optionSelected));
  // };

  // const handleStateChange = (optionSelected) => {
  //   setDatas({ ...datas, ['state_id']: optionSelected });
  //   dispatch(cityList(optionSelected));
  // };

  // const handleCityChange = (optionSelected) => {
  //   setDatas({ ...datas, ['city_id']: optionSelected });
  // };

  const updateDob = (date) => {
    setDob(date);
    sessionStorage.setItem("dob", date);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  if(user && user.access_token) {
    return <Redirect to="/profile" />;
  }
  
  const handleRegister = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if(!accept){
        setAcceptmsg(true)
      }else{
        setAcceptmsg(false)
        if(dob !=''){
          var dobdata = moment(dob).format('YYYY-MM-DD');
        }else{
          var dobdata = new Date();
        }
        const data = {'salut':salut,'name':name,'email':email,'password':password,'mobile':mobile,'dial_code':dialcode,'dob':dobdata,'gender':gender,'marital_status':marital,'country_id':countryid,'state_id':stateid,'city_id':cityid,'picture':picture,'street_address':street,'pincode':pincode,'profession':profession,'profession_detail':professiondetail,'company_name':company,'whatsapp_number':whatsapp, 'education':education, 'pan_number':pan, 'know_matangi':knowmatangi, 'friend_name':friendname, 'friend_mobile':friendmobile, 'know_other':knowother}

        
        let formData = new FormData();
        for(let dataitem in data){
          formData.append(dataitem, data[dataitem]);
        }

        dispatch(register(formData)).then(() => {
          setSuccessful(true);
          toast('Registered Successfully', {
            theme: "dark"
          });
          const navtype = localStorage.getItem("navtype");
          if(navtype){
            if(navtype =='mandir'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==='1'){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/mandir-payment");
              window.location.reload();
            }
            if(navtype =='karma'){
              const userflag = localStorage.getItem("profile_status");
              console.log(userflag);
              if(userflag =='1'){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/karmayogam-home");
              window.location.reload();
            }
            if(navtype ==='1'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/ulchemy");
              window.location.reload();
            }
            if(navtype ==='2'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/ulchemy-elite");
              window.location.reload();
            }
            if(navtype ==='3'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/divija");
              window.location.reload();
            }

            if(navtype ==='4'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/kaivalya");
              window.location.reload();
            }

            if(navtype ==='5'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/vision");
              window.location.reload();
            }

            if(navtype ==='6'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/shivratri");
              window.location.reload();
            }

            if(navtype ==='7'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/karmayogam");
              window.location.reload();
            }

            if(navtype ==='8'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/paradesham");
              window.location.reload();
            }

          }else{
            props.history.push("/upcoming-events");
            window.location.reload();
          }
        })
        .catch(() => {
          setSuccessful(false);
        });
      }
    }
  };

  const onOpenModal = (e) => {
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  return (

    <section className="section login-sec">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
          {!successful && (
						<div className="login-right">
              <p className="text-center">Already Registered ? <Link to="/login">Click here to Login</Link></p>
							<h3>Register</h3>
							<p>Please Register to continue</p>
							<Form onSubmit={handleRegister} ref={form}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label style={{width:'100%',display:'inline-block'}}> Name <sup>*</sup></label>
                      <div className="form-group" style={{width:'16%',display:'inline-block'}}>
                          
                          <Select name="salut" className="form-control" value={ salut } onChange={handleSalutChange} style={{paddingLeft:'0px',paddingRight:'1px'}}>
                            <option value="">Select</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Mrs.">Mrs.</option>
                          </Select>
                      </div>
                      <div className="form-group" style={{width:'84%',display:'inline-block',verticalAlign: 'top'}}>
                          <Input type="text" className="form-control" name="name" value={ name } onChange={handleNameChange} validations={[required]} placeholder="Enter Name..."  />
                      </div>
                    </div>
                    <div className="form-group">
                      <label> Email address <sup>*</sup></label>
                      <Input type="text" className="form-control" name="email" value={email} onChange={handleEmailChange}  validations={[required, validEmail]} placeholder="Enter Email ID..." />
                    </div>
                    <div className="form-group">
                      <label> Mobile Number <sup>*</sup></label>
                      {/* <Input type="text" className="form-control" name="mobile" value={datas.mobile} onChange={handleInputChange} validations={[required, vmobile]} placeholder="Enter Mobile Number..." /> */}
                      <IntlTelInput
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        separateDialCode={true}
                        autoHideDialCode={true}
                        defaultCountry="in"
                        value={fullmobile}
                        allowDropdown={true}
                        onPhoneNumberChange={handleMobileChange}
                      />
                    </div>
                    <div className="form-group">
                      <label> Password <sup>*</sup></label>
                      <Input type="password" className="form-control" name="password" value={password} onChange={handlePasswordChange} validations={[required, vpassword]} placeholder="Create password..." />
                    </div>
                    <div className="form-group">
                      <label> Date of Birth (Format : DD/MM/YYYY)<sup>*</sup></label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date of Birth"
                        value={dob}
                        disableFuture={true}
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onChange={(newValue) => {
                          updateDob(newValue);
                        }}
                        autoOk={true}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <input className="form-control" ref={inputRef} {...inputProps} placeholder="__/__/____"/>
                             */}
                            <InputMask className="form-control" ref={inputRef} {...inputProps} placeholder="__/__/____" mask="99/99/9999" maskChar="_" />
                            {InputProps?.endAdornment}
                          </Box>
                          // node_modules\@mui\x-date-pickers\internals\components\wrappers\DesktopWrapper.js (role: "dialog-popper")
                        )}
                      />
                    </LocalizationProvider>
                    </div>
                    <div className="form-group">
                      <label> Gender <sup>*</sup></label>
                      <Select name="gender" className="form-control" value={ gender } onChange={handleGenderChange} validations={[required]}>
                        <option value="">Select Option</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="transgender">Transgender</option>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label> Marital status <sup>*</sup></label>
                      <Select name='marital_status' className="form-control" value={ marital } onChange={handleMaritalChange} validations={[required]}>
                        <option value="">Select Option</option>
                        <option value="Married">Married</option>
                        <option value="Unmarried">Unmarried</option>
                        <option value="Divorced">Divorced</option>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label> Profile Picture</label>
                      <Input type="file" className="form-control" name="picture" onChange={handlePictureChange} placeholder="Select Profile Picture..." />
                    </div>
                    
                    <div className="form-group">
                      <label> Select Country <sup>*</sup></label>
                      <SelectSearch options={country} value={ countryid } onChange={handleCountryChange} search filterOptions={fuzzySearch} name="country_id" emptyMessage="Not found"  placeholder="Choose your country" validations={[required]}/>
                    </div>
                    <div className="form-group">
                      <label> Select State <sup>*</sup></label>
                      <SelectSearch options={state} value={ stateid } onChange={handleStateChange} search filterOptions={fuzzySearch} name="state_id" emptyMessage="Not found"  placeholder="Choose your State" required/>
                    </div>
                    <div className="form-group">
                      <label> Select City <sup>*</sup></label>
                      <SelectSearch options={city} value={ cityid } onChange={handleCityChange} search filterOptions={fuzzySearch} name="city_id" emptyMessage="Not found"  placeholder="Choose your City" required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Address <sup>*</sup></label>
                      <Input type="text" className="form-control" name="street_address" value={street} onChange={handleStreetChange} validations={[required]} placeholder="Enter Address..." />
                    </div>
                    <div className="form-group">
                      <label> Pincode <sup>*</sup></label>
                      <Input type="text" className="form-control" name="pincode" value={pincode} onChange={handlePincodeChange} validations={[required, vnumber]} placeholder="Enter Pincode..." />
                    </div>
                    <div className="form-group">
                      <label> Education</label>
                      <Input type="text" className="form-control" name="education" value={education} onChange={handleEducationChange} placeholder="Enter Education..." />
                    </div>
                    <div className="form-group">
                      <label> Profession <sup>*</sup></label>
                      <Select name='profession' className="form-control" value={ profession } onChange={handleProfessionChange} validations={[required]}>
                        <option value="">Select Profession</option>
                        <option value="Artist">Artist</option>
                        <option value="Business">Business</option>
                        <option value="Chartered Accountant">Chartered Accountant</option>
                        <option value="Civil Service">Civil Service</option>
                        <option value="Designer">Designer</option>
                        <option value="Engineer">Engineer</option>
                        <option value="Entertainment Industry">Entertainment Industry</option>
                        <option value="Fashion Industry">Fashion Industry</option>
                        <option value="Govt employee">Govt employee</option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Hotel Management">Hotel Management</option>
                        <option value="IT professional">IT professional</option>
                        <option value="Legal profession">Legal profession</option>
                        <option value="Makeup artists">Makeup artists</option>
                        <option value="Management Consultant">Management Consultant</option>
                        <option value="Management Professional">Management Professional</option>
                        <option value="Medical Industry">Medical Industry</option>
                        <option value="Media Industry">Media Industry</option>
                        <option value="Merchant Navy">Merchant Navy</option>
                        <option value="NGO/ Charity Careers">NGO/ Charity Careers</option>
                        <option value="Private sector employee">Private sector employee</option>
                        <option value="Scientist">Scientist</option>
                        <option value="Student">Student</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Technology Careers">Technology Careers</option>
                        <option value="Other">Other</option>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label> Profession Detail <sup>*</sup></label>
                      <Input type="text" className="form-control" name="profession_detail" value={professiondetail} onChange={handleProfessionDetailChange} placeholder="Enter Profession Detail..." validations={[required]}/>
                    </div>
                    <div className="form-group">
                      <label> Company Name</label>
                      <Input type="text" className="form-control" name="company_name" value={company} onChange={handleCompanyChange} placeholder="Enter Company Name..." />
                    </div>
                    <div className="form-group">
                      <label> Whatsapp number</label>
                      <Input type="text" className="form-control" name="whatsapp_number" value={whatsapp} onChange={handleWhatsappChange} validations={[vmobileoptional]}  placeholder="Enter Whatsapp Number..." />
                    </div>
                    <div className="form-group">
                      <label> PAN - Mandatory to claim 80G</label>
                      <Input type="text" className="form-control" name="pan_number" value={pan} onChange={handlePanChange} placeholder="Enter PAN Number..." />
                    </div>
                    <div className="form-group">
                      <label> How did you know about the program?<sup>*</sup></label>
                      <Select name='know_matangi' className="form-control" value={ knowmatangi } onChange={handleKnowMatangi} validations={[required]}>
                        <option value="">Select Option</option>
                        <option value="Facebook">Facebook</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Friend">Friend</option>
                        <option value="Other">Other</option>
                      </Select>
                    </div>
                    <div className={knowmatangi =="Friend" ? "form-group d-block" : "form-group d-none"}>
                      <label> Friend Name</label>
                      <Input type="text" className="form-control" name="friend_name" value={friendname} onChange={handleFriendName} placeholder="Enter Friend Name..." />
                    </div>
                    <div className={knowmatangi =="Friend" ? "form-group d-block" : "form-group d-none"}>
                      <label> Friend Mobile Number</label>
                      <Input type="text" className="form-control" name="friend_mobile" value={friendmobile} onChange={handleFriendMobile} placeholder="Enter Friend Mobile Number..." />
                    </div>
                    <div className={knowmatangi =="Other" ? "form-group d-block" : "form-group d-none"}>
                      <label> If others, Describe</label>
                      <Input type="text" className="form-control" name="know_other" value={knowother} onChange={handleKnowOther} placeholder="If others, Describe..." />
                    </div>

                    <div className="form-group form-check">
                        <input type="checkbox" className="form-control display-inline" value={accept}  onChange={handleAccept}/> I accept <a onClick={onOpenModal} style={{'cursor':'pointer'}}> Terms and Conditions</a>
                        {acceptmsg && (
                        <div className="alert alert-danger" role="alert">Please accept Terms and Conditions</div>
                        )}
                    </div>
                    <button className="btn btn-primary loginbtn">Sign Up</button>
                  </div>
                  
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
              {(!successful && message) && (
                <div className="form-group">
                  <div className={"alert "+msg_type} role="alert">
                    {message}
                  </div>
                </div>
              )}

              <div className="newuser-sec">
								<p>Already Registered ? <Link to="/login">Click here to Login</Link></p>
                <p>If you are facing any issues, Please call us @ <b>9655992559 / 08069016130</b></p>
							</div>
						</div>
            )}

            {successful &&(
              <div className="login-right">
              <h3>Registered Successfully</h3>
              <p><Link to={"/login"}>Click here to Login</Link></p>
              </div>
            )}
					</div>
				</div>
			</div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className='player-wrapper'>
            <h4>Terms and Conditions</h4>
            <hr />
            <h4>Agreement</h4>
            <p> I acknowledge that Matangi Foundation will provide programs requiring both physical and mental exertion on my behalf. I will at all times act within my own level of comfort, taking particular care of my injury or complaint that I carry when participating. I acknowledge that this program contains both physical and dynamic practice that requires both mental and physical focus in order to avoid potential injury, and that injury and soreness are a real and material risk in every program. I will always keep Matangi Foundation informed of my comfort levels and will immediately cease any activity if I become uncomfortable with my condition. I acknowledge that Matangi Foundation is providing guidance based on the experience and education of its teachers, and no way do they claim to be medical practitioners, it is my responsibility to decide, to follow or not to follow that advice and guidance given by Matangi Foundation, and in no way do I hold Matangi Foundation responsible for any injury or soreness acquired while undertaking the practices taught in the program, I hereby commit that the information that I have provided is accurate and fully reveals the nature of my state of health as on date of this document. In addition, I undertake to continuously inform the Matangi Foundation of any changes to my health status whilst attending the program. I acknowledge that the donation paid to Matangi Foundation is non-refundable and non-transferable.</p>
            <p>I, Agree to the statements above and I am fully responsible for any consequences and willing to compensate for any loss or damage caused to the organization.</p>
            <h4>Privacy Policy</h4>
            <p>This platform is owned and operated by Dakshina Foundation. In this Privacy Policy, “we”, “us” and “our” refer to Dakshina Foundation. Dakshina Foundation is a non-profit organization, initiated with a divine mission of bringing quality difference to the smothering lifestyle of people who are caught up in an intricate web of the fast track modern era. Dakshina Foundation strives with unstinted focus to facilitate people to unwind and cultivate their hidden potential, channelize their thoughts and actions towards enhancing their productivity in all respective spheres.</p>
            <p>The information provided by (<b>“Matangi Foundation Platform”</b>) on https://matangifoundation.org/ website or mobile application is for general informational purposes only. We recognize that you may be concerned about the information we collect from you through our website and how we treat that information. The Dakshina Foundation values, respects and protects your privacy and therefore we have taken necessary precautions to be in compliance with the provisions of Information Technology Act, 2000. This Privacy Policy enlists the types of information that is collected and recorded by Matangi Foundation platform, and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in https://matangifoundation.org/. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <h4>Collection of Personal Data (“Personal Data”)</h4>
            <p>Your privacy and security are our priority, and we strive to incorporate the best security practices in our operations. </p>
            <p>If you are a donor, when you make an online donation on the Matangi Foundation Platform, we collect Personal Data such as your name, billing address, Permanent Account Number, telephone number, email address and any other information provided by you. </p>
            <p>The Personal Data that you are asked to provide, and the reasons for asking the same, shall be made clear to you when you are requested to provide your Personal Data. We only collect information that we believe to be relevant and for the purpose of acknowledging receipt of the donation. </p>
            <p>If you contact us directly, we may ask you to provide additional information about you such as your name, email address, phone number, and the contents of the message and/or attachments you may send us, and any other information you choose to provide. Further, when you register for a program on Matangi Foundation Platform, we may ask for your contact information, including, but not limited to, name, occupation, gender, date of birth, address, email address, medical history and phone number.</p>
            <p>Matangi Foundation Platform will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data shall take place to any other person or organization. Your Personal Data will not be shared with or sold to any third-party companies or organizations.</p>
            <h4>Collection of Non-Personal Data</h4>
            <p>We gather statistical and other analytical information collected on an aggregate basis of all visitors on Matangi Foundation Platform. This Non-Personal Data comprises information that cannot be used to identify or contact you, such as demographic information regarding, for example, clipped or anonymized user IP addresses, browser types and other anonymous statistical data involving the use of Matangi Foundation Platform to understand geography of our visitors and help us better organize the website.</p>
            <h4>How we use your information</h4>
            <p>We will process any Personal Data you provide to us for the following purposes:</p>
            <ul style={{"listStyle":'none'}}>
                <li>1.	Provide, operate, and maintain our website;</li>
                <li>2.	Improve, personalize, and expand our website;</li>
                <li>3.	Understand and analyze how you use our website;</li>
                <li>4.	Develop new products, services, features, and functionality;</li>
                <li>5.	Communicate with you, either directly or through one of our partners, to provide you with updates and other information relating to the website, or for marketing and promotional purposes;</li>
                <li>6.	Send you emails;</li>
                <li>7.	Find and prevent fraud;</li>
                <li>8.	To respond to any customer service-related queries; </li>
                <li>9.	Creating profiles for donees;</li>
            </ul>

            <p>We also use this information to send you an 80G certificate (if applicable) upon your request and to communicate with you in case of any trouble in processing the donation.</p>
            <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please email us at info@matangifoundation.org</p>
            <h4>Cookies</h4>
            <p>When you visit the Matangi Foundation Platform, we send one or more cookies – a small file containing a string of characters – to your computer or other device that uniquely identifies your browser. We use cookies to improve the quality of our service, including for storing user preferences, improving search results and ad selection, and tracking user trends. Matangi Foundation Platform also uses cookies in its advertising services to help advertisers and publishers serve and manage ads across the web. We may set one or more cookies in your browser when you visit a website. Cookies can make the site more useful by storing information about your preferences on particular sites, thus enabling website owners to provide more useful features for their users. The information collected by "cookies" is anonymous aggregated research data and contain no name or address information or any information that will enable anyone to contact you via telephone, e-mail or any other means. Most browsers are initially set to accept cookies. If you would prefer, you can set your browser to disable cookies or inform you when they are set. However, by disabling them, you may not be able to take full advantage of our website. This Privacy Policy does not cover cookies as used by the web- advertising partners. You are always free to decline such cookies, if your browser permits, or to ask your browser to indicate when a cookie is being sent.</p>
            <p>Disclosure of Personal Data</p>
            <p>We shall not disclose your Personal Data to third parties unless you have consented to this disclosure. We shall disclose your Personal Data if we believe in good faith that we are required to disclose it in order: (a) to comply with any applicable law, a summons, a search warrant, a court or regulatory order or other statutory or legal requirement; (b) to enforce the Matangi Foundation Terms & Conditions or to protect our rights; or (c) to protect the safety of users of the Matangi Foundation Platform.</p>
            <p>We will share limited Personal Data of the users as required with our agents, representatives, service providers and contractors in order to offer you services in connection with our website, communicate news and information about programs, provide customer service, enhance and improve user experiences, and complete financial transactions. These service providers are authorized to use your information only to the extent necessary to serve these purposes, or as otherwise authorized by this policy. We also share your Personal Data when you make a donation or when you donate to a project, we share your name and contact information and the amount of your donation with the relevant non-profit organisation. If Matangi Foundation merges with, or becomes an affiliate of another organization, we will ensure the confidentiality of any Personal Data involved in such transactions and provide notice before Personal Data is transferred and becomes subject to a different privacy policy.</p>
            <h4>Retention</h4>
            <p>We will retain your information for as long as needed to provide our services. We may also retain and use your Personal Data in order to comply with our contractual or legal obligations, audit requirements, resolve disputes, prevent abuse and other statutory/legal purposes. For example, tax laws in India may require us to keep contact information and contribution level of donors on a file.</p>
            <p>The information we collect is only for the improvement of our services. We may retain your information to provide certain services to users, to recognize them the next time they use Matangi Foundation Platform or to send promotional emails and SMS.</p>
            <h4>Amendments to this Policy</h4>
            <p>If we decide to change our privacy policy, we will post those changes to this privacy statement so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We will use information in accordance with the privacy policy under which the information was collected. We encourage you to periodically review this policy for the latest information on our privacy practices.</p>
            <h4>Rights</h4>
            <p>You have certain rights with respect to the Personal Data we collect about you. Upon your request, we tell you what information we hold about you, we rectify any incomplete or inaccurate information and also restrict the use of your information. We will make reasonable efforts to delete your information if you ask us to do so, unless we are otherwise required to keep it.</p>
            <h4>Links to other websites</h4>
            <p>The Matangi Foundation Platform contains links to other websites including our partners. Dakshina Foundation is not responsible for the privacy policy or practices on such linked sites. This Privacy Policy governs only information collected on this website. When linking to another website, you should read the privacy policy stated on that website. Please be aware that the terms of our Privacy Policy do not apply to these outside websites. Our site contains links to other sites and resources provided by third parties for your convenience and information only. We accept no liability in connection with any third-party website accessed via a link from the Matangi Foundation Platform, or any contract entered into or through a third-party website. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>
            <h4>Copyright Protection</h4>
            <p>All content on this Matangi Foundation Platform including graphics, text, icons, interfaces, audio clips, logos, images and software is the property of Dakshina Foundation and/or its content suppliers and is protected by Indian and international copyright laws. The arrangement and compilation (meaning the collection, arrangement, and assembly) of all content on this Matangi Foundation Platform is the exclusive property of Dakshina Foundation and protected by Indian and international copyright laws. Permission is given to use the resources of this Matangi Foundation Platform only for the purposes of making enquiries, making a donation or placing an order for the purchase of products. Any other use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this Matangi Foundation Platform can only be made with the express permission of Dakshina Foundation. All other trademarks, brands and copyrights other than those belonging to Dakshina Foundation belong to their respective owners and are their property.</p>
            <h4>Security</h4>
            <p>We understand the importance of keeping your information secure and confidential. To safeguard your information, we have implemented effective policies for this purpose. When you make a donation, information about your payment card is transmitted securely through a Payment Gateway. Payment Gateways allow you to make a payment electronically using your credit card, debit card, net banking or other payment methods.</p>
            <p>The payment gateways collect data that may include your contact details, your card details and the transaction details which facilitates your online payment. We do not collect or store any of these personal details and we do not have any control over them. This data is completely managed by the Payment Gateway tools according to their own privacy policies.</p>
            <p>We have taken and will continue to take reasonable efforts to protect Personal Data in our possession or control by making reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks.</p>
            <p>While we strive to protect your Personal Data, we cannot ensure the security of any Personal Data which you may have transmitted to us, via the Internet, and we urge you to take every precaution to protect your Personal Data and use a secure browser.</p>
            <h4>Consent</h4>
            <p>By using our website “https://matangifoundation.org/”, you hereby consent to our Privacy Policy and agree to its terms.</p>
            <h4>Contact Us</h4>
            <p>If you have any comments or questions about the collection, use or disclosure of your Personal Data or this Privacy Policy Statement, please contact us in writing at the address below referencing ‘Privacy Policy Statement’:</p>
            <br/>
            <p>Data Protection Officer <br/>Email: info@matangifoundation.org<br/>Telephone no: 0422 404 0422</p>
            
            <h4>Refund And Cancellation Policy</h4>
            <hr />
            <p>Dakshina Foundation is instituting a refund and cancellation policy for the donors who have given donations to the Dakshina Foundation or have opted for any services, projects, programs for which a receipt for tax exemption has been issued.</p>
            <h4>Tax Exemption</h4>
            <p>All the payments paid to Dakshina Foundation Trust are eligible for an exemption (i.e. tax benefit) of 50%, under the section 80G(5)(vi) of Income Tax Act 1961 and are considered as donations. While opting for any service that Dakshina Foundation provides, under different projects or for different programs, a receipt for tax exemption will be issued to the donor.</p>
            <h4>Refund Policy</h4>
            <p>Dakshina Foundation is not obliged to make refunds and shall decline any requests for refund of donations, for which a tax exemption certificate has been issued. Any refund/cancellation for the donated amount by any donor will not be entertained, whether online, electronically or through any other means.</p>
            <p>No cash or refund of money shall be allowed. Once the donation is received for a cause, the same will not be refunded to the donor. No cancellations are allowed for any services, projects, programs or any other purpose for which the donation has been provided.</p>
            <p>Dakshina Foundation expects that all donors will exercise due care and diligence while making donations. Any request for cancellations and refund donations once duly placed, shall not be entertained under any circumstances.</p>
            <p>Dakshina Foundation</p>
            <p>Email: info@matangifoundation.org</p>
            <p>All decisions of Dakshina Foundation in this regard will be final and binding on the donor.</p>
        </div>
        </Modal>
        <ToastContainer/>
		</section>
  );
};

export default Register;
