import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Tilt from 'react-tilt';

const MatangiFoundation = (props) => {
  const history = useHistory(); 
  
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Matangi Foundation | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
  }, [dispatch]);

  const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
  }
  
  return (
    <div>
    <section className="section widsomhome">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-sec">
              <h1 className="text-center">Matangi Foundation</h1>
            </div>
          </div>
            <div className="col-md-12 foundation">
                <div className="row">
                  <div className="col-md-6 foundation">
                  <p>In a view to raise every being to their highest potential and with a deep sense of commitment to unite consciously all beings in the world, Matangi foundation is also working towards eradicating hunger-stricken society and fear in people’s minds by imparting valuable wisdom through a multitude of programs and social initiatives. From this vision stems the following:</p>
                <h4>Matangi Devi Mandir</h4>
                <p>Guruji Mithreshiva intends to build a majestic temple town in approximately 250 acres of land with Raja Matangi Devi as the main deity who bestows all riches available to mankind. </p>
                
                  </div>
                  <div className="col-md-6">
                    <img src="/img/matangi-foundation.png" className="img-fluid" style={{"width":"50%"}}></img>
                  </div>
                </div>
                <h4>Matangi Devi Gurukulam </h4>
                <p>A gurukulam and centre for herbology is to be built in the land adjacent to the temple complex. </p>
                <p>This Gurukulam will shine as a spiritual centre to establish a recognised educational institution that imparts ancient wisdom through Hindu Gurukul system and tools. The aim of this spiritual centre is to unite people of the world under the umbrella vision “Vasudhaiva Kutumbakam - the world is one family” and to bring awareness about the great aspects of Hindu culture to the whole of humanity.</p>
                <h4>Herbal Cultivation</h4>
                <p>Taking measures to promote herbal cultivation such as medicinal plants with a resolve to inculcate a more healthy style of living in mankind. With the help of NAM Scheme of AYUSH Ministry, this herbal cultivation aims to engender not only a healthy humanity but also support the livelihood of farmers and manage/restore their natural resources</p>
                <h4>Programs for inner transformation</h4>
                <p>To manifest the vision of bringing about awareness in mankind to uplift inner consciousness, there are many programs guided by Guruji Mithreshiva</p>
                <ul>
                    <li>Vision - Envisioning life</li>
                    <li>Divija - Second Birth</li>
                    <li>Divija Plus - Feel the rhythm of life</li>
                    <li>Kaivalya - Song of Silence</li>
                </ul>
                <h4>Social Initiatives</h4>
                <p>Fear is one of the biggest diseases in the world. Earning divine blessings by contributing to the welfare of the world, we are following Dharma. People look for solutions only in the time of crisis and we wish to avoid this crisis right from the beginning so as to never end up in such a situation. We are always there for you and keeping this in view, many social initiatives are being undertaken such as</p>
                <ul>
                    <li>Neerukku Nandri - Save water campaign</li>
                    <li>Mithra - Contribute to improve the lives of others in time of crisis</li>
                    <li>Sanjeevini - Pray to stay strong no matter how hard the wind blows</li>
                </ul>
            </div>
        </div>
      </div>
    </section>
    <div id="site-background">
        <div className="layer-wrapper">
            {/* <div className="layer1" style={{ "transform": "translateY(0px)" }}>
                <div className="element2"><img src="/img/element1.png" alt="" /></div>
            </div> */}
            <div class="layer2" style={{ "transform": "translateY(68.428px)" }}>
                <div class="element4"><img src="/img/element7.png" alt="" /></div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default MatangiFoundation;
