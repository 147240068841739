import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useParams} from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { isEmpty } from 'lodash';
import { categoryDetail } from "../actions/api-media";
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'rc-datepicker/lib/style.css';
import { useWish } from '../hooks';

const MediaCategory = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
  	const mediacategory = useSelector(state => state),
    { mediaReducer } = mediacategory,
    { category_detail } = mediaReducer;
	
    const [open, setOpen] = useState(false);
    const [videourl, setVideourl] = useState(''); 
	const [detail,setDetail]=useState([]);
	const [categoryname,setCategoryName]=useState([]);
	useEffect(() => {
		document.title = "Media Category | Matangi";
		dispatch(categoryDetail(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (!isEmpty(category_detail.data)) {
			setDetail(category_detail.data);
			setCategoryName(category_detail.category_name);
		}
	}, [category_detail]);

    const onOpenModal = (e) => {
        var videlink = e.currentTarget.dataset.video;
        setVideourl(videlink);
        setOpen(true);
    };
    const onCloseModal = () => setOpen(false);
    const copyToClipBoard = async copyMe => {
        try {
            var link = copyMe.match(/\bhttps?:\/\/\S+/gi);
			var final_link = link[0].replace(/^"|"$/g, '');
			await navigator.clipboard.writeText(final_link);
            toast('Link Copied Successfully', {
                theme: "dark"
            });
        } catch (err) {
    
        }
    };

    const { isMediaOnWish, addToMediaWish } = useWish();
    const addMedia = (e) => {
        var source = e.currentTarget.getAttribute("data-id");
        addToMediaWish(source);
    };

    const [shareopen, setShareOpen] = useState(false);
	const [sharelink, setShareLink] = useState('');
	const onShareOpenModal = async copyMe => {
        try {
			var link = copyMe.match(/\bhttps?:\/\/\S+/gi);
			var final_link = link[0].replace(/^"|"$/g, '');
			setShareLink(final_link)
			setShareOpen(true);
			await navigator.clipboard.writeText(final_link);
			toast('Link Copied Successfully', {
				theme: "dark"
			});
			
        } catch (err) {
    
        }
    };
    const onShareCloseModal = () => setShareOpen(false);
    
  return (
    <div>
        <section className="section-recent">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<Link to="/media" className="btn btn-sm btn-info backbtn"><i className="fa fa-chevron-left"></i> Back</Link>
						<div className="title-sec"><h1>{ categoryname }</h1></div>
					</div>
					
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            { detail && detail.map((media, sindex) => ( 
                                <div className="col-md-3" key={sindex}>
                                    <div className="thumb-box">
                                    <div className="thumb-img">
                                        <div className="media-img mediavideo-img">
                                        <img src={ media.image } alt="" onError={(e)=>{e.target.src='/img/placeholder.png'}}/>
                                        </div>
                                        <div className="video-info">
                                        {media.media_time !=='' && (
                                        <div className="info-time">
                                            <p>{ media.media_time }</p>
                                        </div>
                                        )}
                                        <div className="info-share">
                                            <p className="first" data-for="share" data-tip="Click to Copy the Link" data-iscapture="true" onClick={ () => onShareOpenModal(media.media) }><span className="fa fa-share-alt"></span></p>
            
                                            <p className={ isMediaOnWish(media.id) ? 'second wished' : 'second'} onClick={addMedia} data-id={media.id}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" className="svg-inline--fa fa-heart fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                            </p>
                                        </div>
                                        <div className="playbtn">
                                            <div className="sonar-emitter" data-video={ media.media } onClick={onOpenModal}>
                                                <div className="sonar-wave sonar-wave1"></div>
                                                <div className="sonar-wave sonar-wave2"></div><span className="fa fa-play"></span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="thumb-content">
                                        <h5>{ media.title.length > 55 ? media.title.substring(0, 40)+ "..."  : media.title }</h5>
                                    </div>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
						
				</div>
			</div>
		</section>
        <Modal open={open} onClose={onCloseModal} center>
            <div className='player-wrapper' dangerouslySetInnerHTML={{__html: videourl }}></div>
        </Modal>
        <Modal open={shareopen} onClose={onShareCloseModal} center>
        <div className='player-wrapper shareblock'>
            <a href={"https://api.whatsapp.com/send?text="+sharelink} target="_blank"><img src="/img/whatsapp.png" alt="" className="shareicon"/></a>
            <a href={"https://www.facebook.com/sharer/sharer.php?u="+sharelink} target="_blank"><img src="/img/facebook.png" alt="" className="shareicon"/></a>
        </div>
        </Modal>
        <ReactTooltip
        id="share"
        place='bottom'
        multiline={true}
        />
        <ToastContainer/>
    </div>
  );
};

export default MediaCategory;
