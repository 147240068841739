import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from 'react-validation/build/textarea';
import CheckButton from "react-validation/build/button";
import { isEmail, isNumeric } from "validator";
import { contact } from "../actions/api-auth";

const required = (value) => {
  var valuetrim = value.trim();
  if (!valuetrim) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validNumeric = (value) => {
  if (!isNumeric(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid Number.
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Contact = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const { message, msg_type } = useSelector(state => state.messageReducer);
    const initialState = {
        name: "",
        email: "",
        mobile: "",
        message: "",
    };
    const [datas, setDatas] = useState(initialState);

    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Contact | Matangi";
        localStorage.removeItem("navtype");
        localStorage.removeItem("event_id");
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
  
    const handleContact = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(contact(datas))
            .then(() => {
              setSuccessful(true);
            })
            .catch(() => {
              setSuccessful(false);
            });
        }
    };

  return (
    <div>
    <section className="section login-sec">
			<div className="container">
				<div className="row">
					
					<div className="col-md-6">
                    {!successful && (
						<div className="login-right">
							<h3>Contact Us</h3>
							<p></p>
							<Form onSubmit={handleContact} ref={form}>
								<div className="form-group">
                                    <Input type="text" className="form-control" name="name" value={ datas.name } onChange={handleInputChange} validations={[required]} placeholder="Enter Name..."/>
								</div>
								<div className="form-group">
                                    <Input type="text" className="form-control" name="email" value={ datas.email } onChange={handleInputChange} validations={[required, validEmail]} placeholder="Enter Email..." />
								</div>
                                <div className="form-group">
                                    <Input type="text" className="form-control" name="mobile" value={ datas.mobile } onChange={handleInputChange} validations={[required, validNumeric]} placeholder="Enter Mobile..." />
								</div>
                                <div className="form-group">
                                    <Textarea className="form-control" placeholder="Leave your message..." value={ datas.message } onChange={handleInputChange} name="message" validations={[required]} />
								</div>
								<button className="btn btn-primary loginbtn">Submit</button>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            
                            {message && (
                              <div className="form-group">
                              <div className={"alert "+msg_type} role="alert">
                                {message}
                              </div>
                              </div>
                            )}

						</div>
                        )}

                        {successful &&(
                          <div className="login-right">
                          <h4>Enquiry sent Successfully</h4>
                          <p><Link to={"/"}>Back to Home</Link></p>
                          </div>
                        )}
					</div>
          <div className="col-md-6">
						<div className="login-left">
							<h1 style={{"marginBottom":"40px", "fontSize":"32px"}}>Reach Us @</h1>
              <p><b>Phone :</b> 9655992559 / 08069016130</p>
              <p><b>Email :</b> enquiries@matangifoundation.org</p>
              <p><b>Address :</b> Vennila Building, <br /> 2nd Floor, Yeso Bunk (Near Anjaneyar Temple), <br /> Peelamedu. Coimbatore - 641004</p>  
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  );
};

export default Contact;
