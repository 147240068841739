import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { programList} from "../actions/api-home";

const Eventhome = (props) => {
  const history = useHistory(); 
  const sliders = useSelector(state => state),
  { homeReducer } = sliders,
  { program_lists} = homeReducer;

  const dispatch = useDispatch();
  const [programevent,setProgramEvent]=useState([]);
  useEffect(() => {
    document.title = "Events | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
    dispatch(programList());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(program_lists.data)) {
      setProgramEvent(program_lists.programevent);
    }
  }, [program_lists]);

  const onClickTile = (e) => {
    var source = e.currentTarget.getAttribute("data-source");
    history.push(`/programs/${source}`);
  };

  const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
  }

  return (
    <div>
    <section className="section widsomhome">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-sec">
              <h1 className="text-center">Events</h1>
            </div>
          </div>
            <div className="col-md-12">
            { programevent.length > 0 ? programevent.map((programevent, peindex) => (
              <div className="col-md-3" key={peindex}>
                
                <div className="program-box" style={{backgroundImage: "url(" + programevent.banner + ")"}} data-source={programevent.id} onClick={onClickTile}>
                  <div className="program-imgbox"><img src={ programevent.image } alt="" onError={(e)=>{e.target.src='/img/placeholder.png'}}/></div>
                  <p><Link to={"/programs/" + programevent.id}>{ programevent.name }</Link></p>
                </div>
                
              </div>
              )) : (
                <div className="col-md-3">
                  <div className="program-box" style={{ backgroundImage:"url(img/wisdom/ulchemy-plus-bg.png)" }}>
                    <p>Coming Soon</p>
                  </div>
                </div>
              )
              }
            </div>
        </div>
      </div>
    </section>
    <div id="site-background">
        <div className="layer-wrapper">
            {/* <div className="layer1" style={{ "transform": "translateY(0px)" }}>
                <div className="element2"><img src="/img/element1.png" alt="" /></div>
            </div> */}
            <div className="layer2" style={{ "transform": "translateY(68.428px)" }}>
                <div className="element4"><img src="/img/element7.png" alt="" /></div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Eventhome;
