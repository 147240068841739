import {
    SET_MESSAGE,
    SET_FMESSAGE,
    EVENT_LIST,
    CATEGORY_EVENT,
    RESULT_DATA,
    TRANS_LIST,
    FEEDBACK_LIST,
    LOGOUT
  } from "./types";

  import { config } from './config.js';
  import axios from 'axios';
  import { Redirect } from 'react-router-dom';
  import authHeader from "./auth-header";
  const BASEURL = config.BASEURL;

export const eventList = (id) => async (dispatch) => {
    try {
        const data = { "program_id": id}
        const res = await axios.post(BASEURL + 'event/list', data, { headers: authHeader() });
        dispatch({
            type: EVENT_LIST,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const categoryEvent = () => async (dispatch) => {
  try {
      const res = await axios.get(BASEURL + 'category/event', { headers: authHeader() });
      dispatch({
          type: CATEGORY_EVENT,
          payload: res.data,
      });
  } catch (err) {
      console.log(err);
  }
};

export const transList = (id) => async (dispatch) => {
    try {
        const data = { "program_id": id}
        const res = await axios.post(BASEURL + 'trans/list', data, { headers: authHeader() });
        dispatch({
            type: TRANS_LIST,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const estimatePrice = (id) => async (dispatch) => {
    try {
        const res = await axios.get(BASEURL + 'estimate/price/'+ id, { headers: authHeader() });
        return res.data;
    } catch (err) {
        const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        dispatch({
            type: SET_FMESSAGE,
            payload: message,
        });
        return Promise.reject();
    }
};

export const eventReg = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASEURL + 'event/register', data, { headers: authHeader() });
        if(res.data.success ===2){
            localStorage.removeItem("user");
            localStorage.removeItem("navtype");
            localStorage.removeItem("event_id");
            localStorage.removeItem("reg_id");
            localStorage.removeItem("regprogram_id");
            dispatch({
              type: LOGOUT,
            });
            <Redirect to="/login" />
        }
        return res.data;
    } catch (err) {
        const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        dispatch({
            type: SET_FMESSAGE,
            payload: message,
        });
        return Promise.reject();
    }
};

export const advancePay = (id) => async (dispatch) => {
    try {
        const data = { "register_id": id}
        const res = await axios.post(BASEURL + 'advance/pay', data, { headers: authHeader() });
        if(res.data.success ===2){
            localStorage.removeItem("user");
            localStorage.removeItem("navtype");
            localStorage.removeItem("event_id");
            localStorage.removeItem("reg_id");
            localStorage.removeItem("regprogram_id");
            dispatch({
              type: LOGOUT,
            });
            <Redirect to="/login" />
        }
        return res.data;
    } catch (err) {
        const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        dispatch({
            type: SET_FMESSAGE,
            payload: message,
        });
        return Promise.reject();
    }
};


export const mandirPay = (amount, payment_type, pan) => async (dispatch) => {
    try {
        const data = { "amount": amount, "payment_type": payment_type, "pan": pan}
        const res = await axios.post(BASEURL + 'mandir/pay', data, { headers: authHeader() });
        if(res.data.success ===2){
            localStorage.removeItem("user");
            localStorage.removeItem("navtype");
            localStorage.removeItem("event_id");
            localStorage.removeItem("reg_id");
            localStorage.removeItem("regprogram_id");
            dispatch({
              type: LOGOUT,
            });
            <Redirect to="/login" />
        }
        return res.data;
    } catch (err) {
        const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        dispatch({
            type: SET_FMESSAGE,
            payload: message,
        });
        return Promise.reject();
    }
};

export const resultDecrypt = (id) => async (dispatch) => {
    try {
        const data = { "response_data": id}
        const res = await axios.post(BASEURL + 'resultdecrypt', data, { headers: authHeader() });
        console.log(res);
        dispatch({
            type: RESULT_DATA,
            payload: res.data,
        });
        return Promise.resolve();
    } catch (err) {
        const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
        dispatch({
            type: SET_FMESSAGE,
            payload: message,
        });
        return Promise.reject();
    }
};
  
export const feedback = (data) => async (dispatch) => {
    try {
      const res = await axios.post(BASEURL + 'feedback', data, { headers: authHeader() });
      if(res.data.success ===2){
        localStorage.removeItem("user");
        localStorage.removeItem("navtype");
        localStorage.removeItem("event_id");
        localStorage.removeItem("reg_id");
        localStorage.removeItem("regprogram_id");
        dispatch({
          type: LOGOUT,
        });
        <Redirect to="/login" />
      }
      console.log(res);
      if(res.data.success ===1){
        dispatch({
          type: SET_MESSAGE,
          payload: res.data.message,
        });
        return Promise.resolve();
      }else{
        dispatch({
          type: SET_FMESSAGE,
          payload: res.data.message,
        });
        return Promise.reject();
      }
    } catch (err) {
      const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
      dispatch({
        type: SET_FMESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
};
  
export const feedbackList = (id) => async (dispatch) => {
    try {
        const res = await axios.get(BASEURL + 'feedback/list/'+ id, { headers: authHeader() });

        if(res.data.success ===2){
            localStorage.removeItem("user");
            localStorage.removeItem("navtype");
            localStorage.removeItem("event_id");
            localStorage.removeItem("reg_id");
            localStorage.removeItem("regprogram_id");
            dispatch({
            type: LOGOUT,
            });
            <Redirect to="/login" />
        }
        dispatch({
            type: FEEDBACK_LIST,
            payload: res.data,
        });
        } catch (err) {
        console.log(err);
        }
  };

  export const regform = (data) => async (dispatch) => {
    try {
      const res = await axios.post(BASEURL + 'regform', data, { headers: authHeader() });
      if(res.data.success ===2){
        localStorage.removeItem("user");
        localStorage.removeItem("navtype");
        localStorage.removeItem("event_id");
        localStorage.removeItem("reg_id");
        localStorage.removeItem("regprogram_id");
        dispatch({
          type: LOGOUT,
        });
        <Redirect to="/login" />
      }
      console.log(res);
      if(res.data.success ===1){
        dispatch({
          type: SET_MESSAGE,
          payload: res.data.message,
        });
        return Promise.resolve();
      }else{
        dispatch({
          type: SET_FMESSAGE,
          payload: res.data.message,
        });
        return Promise.reject();
      }
    } catch (err) {
      const message =(err.response && err.response.data && err.response.data.message) || err.message || err.toString();
      dispatch({
        type: SET_FMESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
};