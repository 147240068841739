import {
    ALLMEDIA_LIST,
    FILTER_MEDIA,
    MEDIACATEGORY_DETAIL,
    MEDIATAG_DETAIL
  } from "./types";

  import { config } from './config.js';
  import authHeader from "./auth-header";
  import axios from 'axios';
  const BASEURL = config.BASEURL;
  
  export const allList = (filter_type) => async (dispatch) => {
    try {
      const data = { "filter_type": filter_type}
      const res = await axios.post(BASEURL + 'recentmedia/list', data, { headers: authHeader() });
      dispatch({
        type: ALLMEDIA_LIST,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const filterList = (filtertitle, startDate, endDate, searchtxt) => async (dispatch) => {
    try {
      const data = { "filter_type": filtertitle, "start_date": startDate, "end_date": endDate, "search_txt": searchtxt }
      const res = await axios.post(BASEURL + 'filter/media', data, { headers: authHeader() });
      dispatch({
        type: FILTER_MEDIA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const categoryDetail = (id) => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'mediacategory/' + id, { headers: authHeader() });
      dispatch({
        type: MEDIACATEGORY_DETAIL,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const tagDetail = (id) => async (dispatch) => {
    try {
      const res = await axios.get(BASEURL + 'mediatag/' + id, { headers: authHeader() });
      dispatch({
        type: MEDIATAG_DETAIL,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  
  
  
  