import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { feedback } from "../../actions/api-wisdom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const Kaivalyafeed = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const reg_id = localStorage.getItem("reg_id");

    const { message } = useSelector(state => state.messageReducer);
    const initialState = {
        reg_id: reg_id,
        spouse_attend: "",
        experience: "",
        want_life: "",
        mission_taking: "",
        about_program: "",
    };
    const [datas, setDatas] = useState(initialState);

    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "FeedBack | Matangi";
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
  
    const handleContact = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(feedback(datas))
            .then(() => {
              localStorage.setItem('reg_id', 0);
              localStorage.setItem('regprogram_id', 0);
              setSuccessful(true);
            })
            .catch(() => {
              setSuccessful(false);
            });
        }
    };

  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
                    <div className="col-md-12">
					<div className="col-md-10">
                    {!successful && (
						<div className="login-right">
							<h3>Kaivalya - Feedback</h3>
							<p></p>
							<Form onSubmit={handleContact} ref={form}>
								<div className="form-group">
                                    <label> Matrial Status....If yes, does your spouse attend Kaivalya?</label>
                                    <Input type="text" className="form-control" name="spouse_attend" value={ datas.spouse_attend } onChange={handleInputChange} validations={[required]} placeholder="Matrial Status....If yes, does your spouse attend Kaivalya?..."/>
								</div>
								<div className="form-group">
                                    <label> Mention 3 important things which you have experienced in kaivalya ?</label>
                                    <Input type="text" className="form-control" name="experience" value={ datas.experience } onChange={handleInputChange} validations={[required]} placeholder="Mention 3 important things which you have experienced in kaivalya ?..." />
								</div>
                                <div className="form-group">
                                    <label> What you want in life?</label>
                                    <Input type="text" className="form-control" name="want_life" value={ datas.want_life } onChange={handleInputChange} validations={[required]} placeholder="What you want in life?..." />
								</div>
                                <div className="form-group">
                                    <label> How do you want to be a part this mission of taking?</label>
                                    <Input type="text" className="form-control" name="mission_taking" value={ datas.mission_taking } onChange={handleInputChange} validations={[required]} placeholder="How do you want to be a part this mission of taking?..." />
								</div>
                                <div className="form-group">
                                    <label> Do you want to share anything else about kaivalya?</label>
                                    <Input type="text" className="form-control" name="about_program" value={ datas.about_program } onChange={handleInputChange} validations={[required]} placeholder="Do you want to share anything else about kaivalya?..." />
								</div>
                                
								<button className="btn btn-primary loginbtn">Submit</button>
                                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            
                            {message && (
                                <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                                </div>
                            )}

                                    </div>
                        )}

                        {successful &&(
                            <div className="login-right">
                            <h4>Feedback Updated Successfully</h4>
                            <p><Link to={"/profile"}>Back to Home</Link></p>
                            </div>
                        )}
                                </div>
                    </div>
                    
				</div>
			</div>
		</section>
  );
};

export default Kaivalyafeed;
