import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useParams} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { categoryDetail } from "../actions/api-gallery";
import { SRLWrapper  } from "simple-react-lightbox";
import "react-datepicker/dist/react-datepicker.css";

const GalleryCategory = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
  	const gallerycategory = useSelector(state => state),
    { galleryReducer } = gallerycategory,
    { category_detail } = galleryReducer;
	const options = {
		buttons: {
		  showDownloadButton: false,
		}
	}
	
	const [detail,setDetail]=useState([]);
	const [categoryname,setCategoryName]=useState([]);
	useEffect(() => {
		document.title = "Gallery Category | Matangi";
		dispatch(categoryDetail(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (!isEmpty(category_detail.data)) {
			setDetail(category_detail.data);
			setCategoryName(category_detail.category_name);
		}
	}, [category_detail]);

  return (
    <div>
        <section className="section-recent">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<Link to="/gallery" className="btn btn-sm btn-info backbtn"><i className="fa fa-chevron-left"></i> Back</Link>
						<div className="title-sec"><h1>{ categoryname }</h1></div>
					</div>
					<SRLWrapper options={options}>
					{ detail && detail.map((detail, deindex) => (
						<div className="row" key={deindex}>
							<div className="col-md-12">
								<div className="title-sec"><h3>{ detail.album_sub}</h3></div>
							</div>
							<div className="col-lg-12 col-md-12">
								{ detail.albums && detail.albums.map((album, sindex) => ( 
									<div className="col-lg-2 col-md-3 col-sm-6" key={sindex}>
										<div className="vertical-item gallery-item content-absolute text-center">
											<div className="item-media" srl_elementid="5">
												<img src={ album.image } alt={ album.caption +' ('+album.album_date+')'} />
											</div>
										</div>
									</div>
								))
								}
							</div>
						</div>
						))
					}
					</SRLWrapper>
				</div>
			</div>
		</section>
    </div>
  );
};

export default GalleryCategory;
