import React from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';

import ScrollToTop from './layout/ScrollToTop'
import Header from './layout/header'
import Footer from './layout/footer'
import Home from "./pages/home";
import Wisdom from "./pages/wisdom";
import Gallery from "./pages/gallery";
import Media from "./pages/media";
import Upcoming from "./pages/upcoming";
import GalleryCategory from "./pages/gallerycategory";
import MediaCategory from "./pages/mediacategory";
import GalleryTag from "./pages/gallerytag";
import MediaTag from "./pages/mediatag";
import Login from "./pages/login";
import Register from "./pages/register";
import Ulchemy from "./pages/ulchemy";
import UlchemyElite from "./pages/ulchemyelite";
import Divija from "./pages/divija";
import Kaivalya from "./pages/kaivalya";
import Vision from "./pages/vision";
import Shivratri from "./pages/shivratri";
import Paradesham from "./pages/paradesham";
import Karmayogam from "./pages/karmayogam";
import Wisdomhome from "./pages/wisdomhome";
import Projecthome from "./pages/projecthome";
import Eventhome from "./pages/eventhome";
import Contact from "./pages/contact";
import Forgot from "./pages/forgot";
import PaymentStatus from "./pages/paymentstatus";
import PaymentCheck from "./pages/paymentcheck";
import Profile from "./pages/profile";
import CurrentEvent from "./pages/currentevent";
import HistoryEvent from "./pages/historyevent";
import PaymentList from "./pages/paymentlist";
import PaymentFailedList from "./pages/paymentfailedlist";
import Favourite from "./pages/favourite";
import EventDetail from "./pages/eventdetail";
import MandirPay from "./pages/mandirpay";
import MatangiFoundation from "./pages/matangifoundation";

import Ulchemyfeed from "./pages/feedback/ulchemyfeed";
import Ulchemyelitefeed from "./pages/feedback/ulchemyelitefeed";
import Divijafeed from "./pages/feedback/divijafeed";
import Kaivalyafeed from "./pages/feedback/kaivalyafeed";
import Visionfeed from "./pages/feedback/visionfeed";

import FeedbackView from "./pages/feedbackview";

import UlchemyReg from "./pages/regform/ulchemy";
import UlchemyEliteReg from "./pages/regform/ulchemyelite";
import DivijaReg from "./pages/regform/divija";
import KaivalyaReg from "./pages/regform/kaivalya";
import VisionReg from "./pages/regform/vision";
import ShivratriReg from "./pages/regform/shivratri";
import ParadeshamReg from "./pages/regform/paradesham";

import Privacy from "./pages/privacy";
import Refund from "./pages/refund";
import Disclaimer from "./pages/disclaimer";
import KarmayogamHome from "./pages/karmayogamhome";

import Share from "./pages/share";

function App() {
  
  return (
    <Router>
      <ScrollToTop>
      <div id="box_wrapper">
        <Header/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/programs" component={Wisdomhome} />
          <Route exact path="/projects" component={Projecthome} />
          <Route exact path="/event-main" component={Eventhome} />
          <Route exact path="/programs/:id" component={Wisdom}/>
          <Route path="/gallery" component={Gallery} />
          <Route path="/media" component={Media} />
          <Route path="/upcoming-events" component={Upcoming} />
          <Route path="/category/:id" component={GalleryCategory} />
          <Route path="/mediacategory/:id" component={MediaCategory} />
          <Route path="/tag/:id" component={GalleryTag} />
          <Route path="/mediatag/:id" component={MediaTag} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/ulchemy" component={Ulchemy} />
          <Route exact path="/ulchemy-elite" component={UlchemyElite} />
          <Route exact path="/divija" component={Divija} />
          <Route exact path="/kaivalya" component={Kaivalya} />
          <Route exact path="/vision" component={Vision} />
          <Route exact path="/shivratri" component={Shivratri} />
          <Route exact path="/paradesham" component={Paradesham} />
          <Route exact path="/karmayogam" component={Karmayogam} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/payment/:id" component={PaymentStatus} />
          <Route exact path="/paymentcheck" component={PaymentCheck} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/current-event" component={CurrentEvent} />
          <Route exact path="/history-event" component={HistoryEvent} />
          <Route exact path="/donation-list" component={PaymentList} />
          <Route exact path="/donation-pending" component={PaymentFailedList} />
          <Route exact path="/favourite-list" component={Favourite} />
          <Route path="/eventdetail/:id" component={EventDetail} />
          <Route exact path="/mandir-payment" component={MandirPay} />
          <Route exact path="/matangi-foundation" component={MatangiFoundation} />
          <Route exact path="/karmayogam-home" component={KarmayogamHome} />

          <Route exact path="/ulchemy-feedback" component={Ulchemyfeed} />
          <Route exact path="/ulchemyelite-feedback" component={Ulchemyelitefeed} />
          <Route exact path="/divija-feedback" component={Divijafeed} />
          <Route exact path="/kaivalya-feedback" component={Kaivalyafeed} />
          <Route exact path="/vision-feedback" component={Visionfeed} />

          <Route exact path="/ulchemy-reg/:id" component={UlchemyReg} />
          <Route exact path="/ulchemyelite-reg/:id" component={UlchemyEliteReg} />
          <Route exact path="/divija-reg/:id" component={DivijaReg} />
          <Route exact path="/kaivalya-reg/:id" component={KaivalyaReg} />
          <Route exact path="/vision-reg/:id" component={VisionReg} />
          <Route exact path="/shivratri-reg/:id" component={ShivratriReg} />
          <Route exact path="/paradesham-reg/:id" component={ParadeshamReg} />

          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/refund-policy" component={Refund} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route path="/feedback-view/:id" component={FeedbackView} />

          <Route exact path="/share/:id" component={Share} />
        </Switch>
        <Footer/>
      </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
