import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/api-auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vmobile = (value) => {
  if (value.length < 10 || value.length > 13 || isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Mobile number
      </div>
    );
  }
  if(isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Mobile number
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.authReducer);
  const { message, msg_type } = useSelector(state => state.messageReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Login | Matangi";
  }, [dispatch]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          const navtype = localStorage.getItem("navtype");
          if(navtype){
            if(navtype =='mandir'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==='1'){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/mandir-payment");
              window.location.reload();
            }
            if(navtype =='karma'){
              const userflag = localStorage.getItem("profile_status");
              console.log(userflag);
              if(userflag =='1'){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/karmayogam-home");
              window.location.reload();
            }
            if(navtype ==='1'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/ulchemy");
              window.location.reload();
            }
            if(navtype ==='2'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/ulchemy-elite");
              window.location.reload();
            }
            if(navtype ==='3'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/divija");
              window.location.reload();
            }

            if(navtype ==='4'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/kaivalya");
              window.location.reload();
            }

            if(navtype ==='5'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/vision");
              window.location.reload();
            }

            if(navtype ==='6'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/shivratri");
              window.location.reload();
            }

            if(navtype ==='7'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/karmayogam");
              window.location.reload();
            }

            if(navtype ==='8'){
              const userflag = localStorage.getItem("profile_status");
              if(userflag ==1){
                props.history.push("/profile");
                window.location.reload();
              }
              props.history.push("/paradesham");
              window.location.reload();
            }

          }else{
            props.history.push("/profile");
            window.location.reload();
          }
          
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/profile" />;
  }

  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="login-left">
							<h4 style={{"lineHeight": "53px","fontSize": "28px"}}>In this Universe, We are already connected to each other and every step of yours bring you closer to the divine.</h4>
						</div>
					</div>
					<div className="col-md-6">
						<div className="login-right">
							<h3>Login</h3>
							<p>Please login to continue</p>
							<Form onSubmit={handleLogin} ref={form}>
								<div className="form-group">
                <Input type="text" className="form-control" name="username" value={username} onChange={onChangeUsername} validations={[required,vmobile]}
               placeholder="Mobile Number"/>
								</div>
								<div className="form-group">
                <Input type="password" className="form-control" name="password" value={password} onChange={onChangePassword} validations={[required]}
            placeholder="Password" />
								</div>
								<button className="btn btn-primary loginbtn" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
                </button>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
              <div className="forgotuser-sec">
                <Link to="/forgot">Forgot password?</Link>
              </div>
              {message && (
                <div className="form-group">
                  <div className={"alert "+msg_type} role="alert">
                    {message}
                  </div>
                </div>
              )}
              <div className="newuser-sec">
								<p>New User ? <Link to="/register">Sign Up</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default Login;
