import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavLink, Link, withRouter} from 'react-router-dom';
import { logout } from "../actions/api-auth";
import { clearMessage } from "../actions/message";

const Header = withRouter(({history}) => {
    const { user: currentUser } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    
    useEffect(() => {
      history.listen((location) => {
        dispatch(clearMessage()); // clear message when changing location
      });
    }, [dispatch, history]);
  
    const logOut = (e) => {
      dispatch(logout());
    };

    return (
      <div className="home-page-header">
      <header className="page_header columns_padding_0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <nav className="mainmenu_wrapper">
              { currentUser ? (<p className="currentuser"><span>Welcome, { currentUser.user_name }</span></p> ) : (<p></p>)}
                <ul className="mainmenu nav sf-menu text-center">
                  <li className="list-logo text-center">
                    <Link to="/" className="logo logo_image">
                      <img src="/img/logo.png" alt="" />
                    </Link>
                  </li>
                  {/* <li className="main-nav main-nav-left">
                    <ul>
                      <li>
                        <NavLink  to="/" exact={true} activeClassName="active">Home</NavLink >
                      </li>
                      <li><NavLink  exact={true} to="/wisdom" activeClassName="active">Wisdom</NavLink ></li>
                      <li><NavLink  to="/projects" activeClassName="active">Projects</NavLink ></li>
                      <li><NavLink exact={true} to="/wisdom/7" activeClassName="active">Karmayogam</NavLink ></li>
                    </ul>
                  </li> */}
                  
                  <li className="main-nav main-nav-right">
                    <ul>
                      <li>
                        <NavLink  to="/" exact={true} activeClassName="active">Home</NavLink >
                      </li>
                      <li><NavLink to="/upcoming-events" activeClassName="active">Upcoming</NavLink></li>
                      <li><NavLink  exact={true} to="/programs" activeClassName="active">Programs</NavLink ></li>
                      <li><NavLink  to="/event-main" activeClassName="active">Events</NavLink ></li>
                      <li><NavLink  to="/projects" activeClassName="active">Projects</NavLink ></li>
                      <li><NavLink exact={true} to="/karmayogam-home" activeClassName="active">Karmayogam</NavLink ></li>
                      <li><NavLink to="/mandir-payment" activeClassName="active">Divine Blessings</NavLink></li>
                      <li><NavLink to="/gallery" activeClassName="active">Gallery</NavLink></li>
                      {/* <li><NavLink to="/media" activeClassName="active">AV Gallery</NavLink></li> */}
                      {/* <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li> */}
                      
                      {currentUser && (
                        <li>
                          <NavLink to="/profile" activeClassName="active">Account</NavLink>
                          <ul>
                            <li><NavLink to="/profile">Account</NavLink></li>
                            <li><NavLink to="/login" onClick={logOut}>Logout</NavLink></li>
                          </ul>
                        </li>
                      )}
                      
                      {!currentUser && (
                        <li><NavLink to="/login" activeClassName="active">Login</NavLink></li>
                      )}
                      <li><NavLink to="/contact" activeClassName="active"><img src="/img/contact.png" alt="" /></NavLink></li>
                    </ul>
                  </li>
                </ul>
              </nav>
              
              <div className="toggle_box">
              { currentUser ? ( <p className="currentuser-mobile"><span>Welcome, { currentUser.user_name }</span></p> ) : (<p></p>)}
              {/* <p className="currentuser-mobile"><span>Welcome, { currentUser.user_name && currentUser.user_name.length > 8 ? currentUser.user_name.substring(0, 8)+ "..."  : currentUser.user_name }</span></p> */}
                <div className="toggle_icon">
                  <span className="toggle_menu">
                    <span></span>
                  </span>
                </div>
                <div className="toggle_logo">
                  <Link to="/">
                    <img src="/img/logo.png" alt="" />
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </header>
    </div>
  )})

export default Header
