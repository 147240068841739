import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useParams} from 'react-router-dom'
import { isEmpty } from 'lodash';
import { tagDetail } from "../actions/api-gallery";
import { SRLWrapper  } from "simple-react-lightbox";

const GalleryTag = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
  	const gallerytag = useSelector(state => state),
    { galleryReducer } = gallerytag,
    { tag_detail } = galleryReducer;
	const options = {
		buttons: {
		  showDownloadButton: false,
		}
	}
	const [detail,setDetail]=useState([]);
	const [tagname,setTagName]=useState([]);
	useEffect(() => {
		document.title = "Gallery Tag | Matangi";
		localStorage.removeItem("navtype");
  		localStorage.removeItem("event_id");
		dispatch(tagDetail(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (!isEmpty(tag_detail.data)) {
			setDetail(tag_detail.data);
			setTagName(tag_detail.tag_name);
		}
	}, [tag_detail]);
    console.log(tag_detail.data);
  return (
    <div>

        <section className="section-recent">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<Link to="/gallery" className="btn btn-sm btn-info backbtn"><i className="fa fa-chevron-left"></i> Back</Link>
						<div className="title-sec"><h1>{ tagname }</h1></div>
					</div>
					<SRLWrapper options={options}>
					<div className="col-lg-12 col-md-12">
                        { detail && detail.map((album, sindex) => ( 
                            <div className="col-lg-2 col-md-3 col-sm-6" key={sindex}>
                                <div className="vertical-item gallery-item content-absolute text-center">
                                    <div className="item-media" srl_elementid="5">
                                        <img src={ album.image } alt={ album.caption +' ('+album.album_date+')'} />
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
					</SRLWrapper>
				</div>
			</div>
		</section>
    </div>
  );
};

export default GalleryTag;
