import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { forgot, resetpassword } from "../actions/api-auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vmobile = (value) => {
    if (value.length < 10 || value.length > 13 || isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
    if(isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
  };

const Forgot = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const checkResetBtn = useRef();
    const [mobile, setMobile] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const [successful, setSuccessful] = useState(false);
    const [reset, setReset] = useState(false);
    const [acceptmsg, setAcceptmsg] = useState(false);
    const { message, msg_type } = useSelector(state => state.messageReducer);
    const initialState = {
        otp: "",
        mobile: "",
        password: "",
    };
    const [datas, setDatas] = useState(initialState);

    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Forgot Password | Matangi";
    }, [dispatch]);

    const handleMobileChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
        setMobile(event.target.value);
    };

    const handleCheck = (event) => {
        setConfirmpassword(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
  
    const handleEmail = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(forgot(mobile))
            .then(() => {
              setSuccessful(true);
            })
            .catch(() => {
              setSuccessful(false);
            });
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setReset(false);
        form.current.validateAll();
        if (checkResetBtn.current.context._errors.length === 0) {
            if(datas.password ===confirmpassword){
                setAcceptmsg(false)
                dispatch(resetpassword(datas))
                .then(() => {
                    setReset(true);
                })
                .catch(() => {
                    setReset(false);
                });
            }else{
                setAcceptmsg(true)
            }
        }
    };

  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
                    <div className="col-md-6">
						<div className="login-left">
							
						</div>
					</div>
					<div className="col-md-6">
                    {!successful && (
						<div className="login-right">
							<h3>Forgot Password</h3>
							<p></p>
							<Form onSubmit={handleEmail} ref={form}>
								<div className="form-group">
                                    <Input type="text" className="form-control" name="mobile" value={ mobile } onChange={handleMobileChange} validations={[required,vmobile]} placeholder="Enter Mobile Number..." />
								</div>
								<button className="btn btn-primary loginbtn">Send OTP</button>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            
                            {message && (
                                <div className="form-group">
                                <div className={"alert "+msg_type} role="alert">
                                    {message}
                                </div>
                                </div>
                            )}
						</div>
                    )}

                    {successful &&(
                        !reset ? (
                        <div className="login-right">
                            <h3>Reset Password</h3>
                            <p></p>
                            <Form onSubmit={handleReset} ref={form}>
                                <div className="form-group">
                                    <Input type="text" className="form-control" name="otp" value={ datas.otp } onChange={handleInputChange} validations={[required]} placeholder="Enter OTP..." />
                                </div>
                                <div className="form-group">
                                    <Input type="password" className="form-control" name="password" value={ datas.password } onChange={handleInputChange} validations={[required]} placeholder="Enter Password..." />
                                </div>
                                <div className="form-group">
                                    <Input type="password" className="form-control" name="confirm_password" value={ confirmpassword } onChange={handleCheck} validations={[required]} placeholder="Enter Confirm Password..." />
                                    {acceptmsg && (
                                        <div className="alert alert-danger" role="alert">Password and Confirm Password not Matched</div>
                                    )}
                                </div>
                                <button className="btn btn-primary loginbtn">Reset Password</button>
                                <CheckButton style={{ display: "none" }} ref={checkResetBtn} />
                            </Form>
                            
                            {message && (
                                <div className="form-group">
                                <div className={"alert "+msg_type} role="alert">
                                    {message}
                                </div>
                                </div>
                            )}
                        </div>
                        ) : (
                            <div className="login-right">
                                <h4>Password Resetted Successfully</h4>
                                <p><Link to={"/login"}>Please Login</Link></p>
                            </div>
                        )

                    )}

					</div>
				</div>
			</div>
		</section>
  );
};

export default Forgot;
