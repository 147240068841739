import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { feedback } from "../../actions/api-wisdom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const Ulchemyelitefeed = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const reg_id = localStorage.getItem("reg_id");

    const { message } = useSelector(state => state.messageReducer);
    const initialState = {
        reg_id: reg_id,
        reason_attend: "",
        expected: "",
        learn_program: "",
        practice_program: "",
        participate_upcoming: "",
        wish_volunteer: "",
        about_program: "",
    };
    const [datas, setDatas] = useState(initialState);

    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "FeedBack | Matangi";
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
  
    const handleContact = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(feedback(datas))
            .then(() => {
              localStorage.setItem('reg_id', 0);
              localStorage.setItem('regprogram_id', 0);
              setSuccessful(true);
            })
            .catch(() => {
              setSuccessful(false);
            });
        }
    };

  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
                    <div className="col-md-12">
					<div className="col-md-8">
                    {!successful && (
						<div className="login-right">
							<h3>Ulchemy Elite - Feedback</h3>
							<p></p>
							<Form onSubmit={handleContact} ref={form}>
								<div className="form-group">
                                    <label> Reason for attending the program?</label>
                                    <Input type="text" className="form-control" name="reason_attend" value={ datas.reason_attend } onChange={handleInputChange} validations={[required]} placeholder="Reason..."/>
								</div>
								<div className="form-group">
                                    <label> Do you get what you expected?</label>
                                    <Input type="text" className="form-control" name="expected" value={ datas.expected } onChange={handleInputChange} validations={[required]} placeholder="Enter what you expected?..." />
								</div>
                                <div className="form-group">
                                    <label>Mention 3 things which you have learnt from the program?</label>
                                    <Input type="text" className="form-control" name="learn_program" value={ datas.learn_program } onChange={handleInputChange} validations={[required]} placeholder="learnt from the program?..." />
								</div>
                                <div className="form-group">
                                    <label>Are you willing to follow the practices you have learnt from the program?</label>
                                    <Input type="text" className="form-control" name="practice_program" value={ datas.practice_program } onChange={handleInputChange} validations={[required]} placeholder="Are you willing to follow the practices you have learnt from the program?..." />
								</div>
                                <div className="form-group">
                                    <label>Would you like to participate from upcoming programs?</label>
                                    <Input type="text" className="form-control" name="participate_upcoming" value={ datas.participate_upcoming } onChange={handleInputChange} validations={[required]} placeholder="Would you like to participate from upcoming programs?..." />
								</div>
                                <div className="form-group">
                                    <label>Do you wish to volunteer for Divija programs?</label>
                                    <Input type="text" className="form-control" name="wish_volunteer" value={ datas.wish_volunteer } onChange={handleInputChange} validations={[required]} placeholder="Do you wish to volunteer for Divija programs?..." />
								</div>
                                <div className="form-group">
                                    <label>Do you have anything to say about this program ?</label>
                                    <Input type="text" className="form-control" name="about_program" value={ datas.about_program } onChange={handleInputChange} validations={[required]} placeholder="Do you have anything to say about this program ?..." />
								</div>
								<button className="btn btn-primary loginbtn">Submit</button>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            
                            {message && (
                                <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                                </div>
                            )}

						</div>
                        )}

                        {successful &&(
                          <div className="login-right">
                          <h4>Feedback Updated Successfully</h4>
                          <p><Link to={"/profile"}>Back to Home</Link></p>
                          </div>
                        )}
					</div>
                    </div>
                    
				</div>
			</div>
		</section>
  );
};

export default Ulchemyelitefeed;
