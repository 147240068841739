import {
    ALLMEDIA_LIST,
    FILTER_MEDIA,
    MEDIACATEGORY_DETAIL,
    MEDIATAG_DETAIL
  } from "../actions/types";
  
  const initialState = {
    all_lists: [],
    filter_media:[],
    category_detail:[],
    tag_detail:[],
    datafetched: false,
    loading: false,
    message: '',
    isDeleted: false
  };
  
  
  const mediaReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case ALLMEDIA_LIST:
        return {
          ...state,
          all_lists: action.payload,
          datafetched: true
        };
      case FILTER_MEDIA:
        return {
          ...state,
          filter_media: action.payload,
          datafetched: true
        };
      case MEDIACATEGORY_DETAIL:
        return {
          ...state,
          category_detail: action.payload,
          datafetched: true
        };
      case MEDIATAG_DETAIL:
        return {
          ...state,
          tag_detail: action.payload,
          datafetched: true
        };
      default:
        return state;
    }
  };
  
  export default mediaReducer;
  