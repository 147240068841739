import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { programList, fetchUpdate} from "../actions/api-home";
import Tilt from 'react-tilt';

const Wisdomhome = (props) => {
  const history = useHistory(); 
  const sliders = useSelector(state => state),
  { homeReducer } = sliders,
  { program_lists, datafetched} = homeReducer;

  const dispatch = useDispatch();
  const [programLists,setProgramLists]=useState([]);
  useEffect(() => {
    document.title = "Programs | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
    dispatch(programList());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(program_lists.data)) {
      setProgramLists(program_lists.data);
    }
  }, [program_lists]);

  const onClickTile = (e) => {
    var source = e.currentTarget.getAttribute("data-source");
    history.push(`/programs/${source}`);
  };

  const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
  }
  
  return (
    <div>
    <section className="section widsomhome">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-sec">
              <h1 className="text-center">Programs</h1>
            </div>
          </div>
            <div className="col-md-12">
            { programLists.length > 0 ? programLists.map((program, pindex) => (
            <div className="col-md-3" key={pindex}>
                
                <div className="program-box" style={{backgroundImage: "url(" + program.banner + ")"}} data-source={program.id} onClick={onClickTile}>
                <div className="program-imgbox"><img src={ program.image } alt="" /></div>
                <p><Link to={"/programs/" + program.id}>{ program.name }</Link></p>
                </div>
                
            </div>
            )) : (
              <div>
              <div className="col-md-3">
              <div className="program-box" style={{ backgroundImage:"url(/img/placeholder.png)" }}>
                  
              </div>
              </div>
              <div className="col-md-3">
              <div className="program-box" style={{ backgroundImage:"url(/img/placeholder.png)" }}>
                  
              </div>
              </div>
              <div className="col-md-3">
              <div className="program-box" style={{ backgroundImage:"url(/img/placeholder.png)" }}>
                  
              </div>
              </div>
            </div>
            )
            }
            </div>
        </div>
      </div>
    </section>
    <div id="site-background">
        <div className="layer-wrapper">
            {/* <div className="layer1" style={{ "transform": "translateY(0px)" }}>
                <div className="element2"><img src="/img/element1.png" alt="" /></div>
            </div> */}
            <div className="layer2" style={{ "transform": "translateY(68.428px)" }}>
                <div className="element4"><img src="/img/element7.png" alt="" /></div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Wisdomhome;
