import React, { useEffect} from "react";
import { useDispatch } from "react-redux";

const Disclaimer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Disclaimer | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
  }, [dispatch]);
  
  return (
    <div>
    <section className="section widsomhome">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-sec">
              <h1 className="text-center">DISCLAIMER</h1>
            </div>
          </div>
            <div className="col-md-12 foundation">
                <p>The information provided by Dakshina Foundation on www.matangifoundation.org, referred to as <b>“Platform”</b>  is for general  informational purposes only. All information on the Platform is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Platform. </p>
                <p>Your access to and use of this Platform is subject to the following terms and conditions and all applicable laws. By accessing and browsing this Platform, you accept, without limitation or qualification, these Terms and Conditions and acknowledge that any other agreements between you and Dakshina Foundation are superseded and of no force or effect.</p>
                <ul style={{"listStyle":'none'}}>
                    <li>i)	We do not warrant the accuracy and completeness of the material on the Platform/mobile application. We may make changes to the material from time to time, without notice. The material on the Platform may be out of date. Although we strive to constantly update the content and material, we shall not be liable for any delays in the same.</li>
                    <li>ii)	Under no circumstances shall we be responsible for any loss or damage, resulting from use of the our services, attendance at a Dakshina Foundation event, from any content posted on or through our services, from the conduct of any user of our services, whether online or offline, or damage arising as a result of any bugs, Trojan horses, viruses, worms or other harmful codes or errors.</li>
                    <li>iii)	To the fullest extent permitted by applicable law, we shall not be liable to any user under contract, tort, equity or otherwise for any loss or damages arising out of its connection with the user's use of the Platform or the provision of services by us, whether any of the foregoing are, without limitation, special, incidental, indirect, punitive or consequential and/or whether any of the foregoing are, without limitation, occasioned by the negligence, fault, error, omission, act, or breach of Dakshina Foundation, its employees, contractors or sub-contractors. Without prejudice to the generality of the foregoing, under no circumstances shall we be liable to any user for any consequential, economic or indirect loss or damages, loss of profits, revenue, business, capital, administrative time or loss of use of data or software, however arising.</li>
                    <li>iv)	Part of our platform may contain advertising and sponsorship. Advertising and sponsorship are responsible for ensuring that material submitted. We take no responsibility for third-party advertisements that are posted on our platform. We shall not be responsible for any error or inaccuracy in the advertising materials.</li>
                    <li>v)	We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any user communication.</li>
                    <li>vi)	While we endeavour to ensure that the Platform is normally available 24 hours a day, we are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any email or players due to technical problems or traffic congestion on the Internet or on any of the Dakshina Foundation services or combination thereof, including any injury or damage to users or to any person's computer related to or resulting from participation or downloading materials in connection with our services. Access to the Platform may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond its control.</li>
                    <li>vii)	Commentary and other materials posted on this Platform are not intended to amount to advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to the Platform, or by anyone who may be informed of any of its contents.</li>
                    <li>viii)	Although we may from time to time monitor or review postings, transmissions, bulletin boards, and the like on the Platform, we are under no obligation to do so and we assume no responsibility or liability arising from the content of any such locations nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, danger, or inaccuracy contained in any information within such locations on the website. You are prohibited from posting or transmitting any unlawful, threatening, libellous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law.</li>
                </ul>

            </div>
        </div>
      </div>
    </section>
    <div id="site-background">
        <div className="layer-wrapper">
            {/* <div className="layer1" style={{ "transform": "translateY(0px)" }}>
                <div className="element2"><img src="/img/element1.png" alt="" /></div>
            </div> */}
            <div class="layer2" style={{ "transform": "translateY(68.428px)" }}>
                <div class="element4"><img src="/img/element7.png" alt="" /></div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Disclaimer;
