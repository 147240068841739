import React, { useEffect, useState } from "react";
import { config } from '../actions/config.js';
import authHeader from "../actions/auth-header";
import axios from 'axios';
const BASEURL = config.BASEURL;

const useProfile = (itemsCount) => {
  const [profile, setProfile] = useState([]);

  const fetchProfile = async () => {
    try {
      const res = await axios.get(BASEURL + 'profile/detail', { headers: authHeader() });
      setProfile(res.data);
    } catch (e) {
      
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return {
    profile, fetchProfile
  };
};

export default useProfile;
