import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { isEmpty } from 'lodash';
import { resultDecrypt } from "../actions/api-wisdom";

const Paymentcheck = () => { 
  const { id } = useParams();
  const resultstate = useSelector(state => state),
  { wisdomReducer } = resultstate,
  { result_data } = wisdomReducer;

  const dispatch = useDispatch();
  const [resultData,setResultData]=useState([]);
  useEffect(() => {
    document.title = "Programs | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
    dispatch(resultDecrypt(id));
  }, [dispatch,id]);

  useEffect(() => {
    setResultData(result_data);
    console.log(result_data);
  }, [result_data]);

  return (
    <div>
    <section className="section login-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
                <div className="pay-right">
                    {resultData.info_success ===1 && (
                    <div>
                        {/* <h3>{ resultData.payment_status }</h3> */}
                        <h3>{resultData.success =='1' ? 'Success': 'Failed'}</h3>
                        <ul>
                          {/* {resultData.pay_from =='event' && (
                            <li><strong>Registration ID:</strong> { resultData.regid }</li>
                          )} */}
                            <li><strong>Transaction ID:</strong> { resultData.tracking_id }</li>
                            <li><strong>Total:</strong> { resultData.currency }{ resultData.amount }</li>
                            {resultData.success =='1' ? (
                                <li><strong>Status message:</strong> { resultData.status_message }</li>
                            ): (<li><strong>Status message:</strong> { resultData.message }</li>)}
                        </ul>

                        {resultData.pay_from =='mandir' ? (
                          <p><Link to={"/donation-list"}>Click here to View Info</Link></p>
                        ) : (
                          <div>
                            {resultData.success =='1' && (
                            <p>Your registration number is {resultData.regevent_id}. Further details will be sent to you shortly.</p>)}
                          {/* <p><Link to={"/ulchemy-reg/"+123}>Click here to complete the Registration form</Link></p>
                          { resultData.programid ==1 &&
                            <p><Link to={"/ulchemy-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==2 &&
                            <p><Link to={"/ulchemyelite-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==3 &&
                            <p><Link to={"/divija-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==4 &&
                            <p><Link to={"/kaivalya-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==5 &&
                            <p><Link to={"/vision-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==6 &&
                            <p><Link to={"/shivratri-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          }
                          { resultData.programid ==8 &&
                            <p><Link to={"/paradesham-reg/"+resultData.regid}>Click here to complete the Registration form</Link></p>
                          } */}
                          </div>
                        )}
                        
                    </div>
                    )}

                    {resultData.info_success ===0 && (
                    <div>
                        <h3>Failed</h3>
                        <ul>
                            <li><strong>Message:</strong> { resultData.message }</li>
                        </ul>
                        <p><Link to={"/profile"}>Click here to View Info</Link></p>
                    </div>
                    )}
                </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Paymentcheck;
