import React, { useEffect , useState, useRef} from "react";
import { useParams } from "react-router-dom";

const Share = (props) => {
  const { id } = useParams();
  const androidapp = useRef();
  const iosapp = useRef();
  const [androidurl, setAndroidUrl] = useState('https://play.google.com/store/apps/details?id=dakshina.donor.mithra');
  const [iosurl, setIosUrl] = useState('https://apps.apple.com/uy/app/mithra-life/id1541511935');
  useEffect(() => {
    document.title = "Share | Matangi";
    // if(window.navigator.userAgent.toLowerCase().indexOf("android") > -1){
    //     console.log('andriod');
    //     setAndroidUrl('href', "intent://program/"+id+"#Intent;scheme=matangireactapp;package=com.matangireactapp;S.browser_fallback_url=http://play.google.com/store/apps/details?id=com.wrx.wazirx;end");
    //     androidapp.current.click();
    // }
    // if(['iPad', 'iPhone', 'iPod'].indexOf(window.navigator.platform) >= 0){
    //     console.log('ios');
    //     setIosUrl('href', "intent://program/"+id+"#Intent;scheme=matangireactapp;package=com.matangireactapp;S.browser_fallback_url=http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8;end");
    //     iosapp.current.click();
    // }
    
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        console.log('andriod');
        setAndroidUrl('href', "intent://program/"+id+"#Intent;scheme=matangireactapp;package=com.matangireactapp;S.browser_fallback_url=https://play.google.com/store/apps/details?id=dakshina.donor.mithra;end");
        androidapp.current.click();
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        console.log('ios');
        setIosUrl('href', "intent://program/"+id+"#Intent;scheme=matangireactapp;package=com.matangireactapp;S.browser_fallback_url=https://apps.apple.com/uy/app/mithra-life/id1541511935;end");
        iosapp.current.click();
    }

  }, [id]);

  return (
    <div className="wisdom-btn text-center">
      <a href={androidurl} className="registerbtn" ref={androidapp}>Android App</a>
      <a href={iosurl} className="registerbtn" ref={iosapp}>IOS App</a>
    </div>
)};

export default Share;
